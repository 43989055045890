<template>
    <van-pull-refresh
            v-model="isLoading"
            success-text="刷新成功"
            @refresh="onRefresh">
        <van-notice-bar
                v-if="noticeText != '' && noticeText != null"
                left-icon="volume-o"
                :text="noticeText"
        />
        <div style="height:100vh;background-color:#58B6FC"
             element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
            <van-row v-for="(items, index) in carPackageInfo" class="ds"
                     style="margin:4px;text-align: center">
                <van-col style="width:100%;height:68px;border:2px solid gray;border-radius:5px;background-color:white"
                         @click="getCarPackage(items)">
                    <div style="width:100%;height:100%;display:flex;">
                        <div style="display:flex;width:30%;background-color:#1e9fff;border-radius:3px 0px 0px 3px">
                            <van-icon v-if="items.isSelect == true" style="width:50%;text-align:right" name="success"
                                      color="orange" size="70"/>
                            <van-icon v-else style="width:50%;text-align:right" color="orange" size="40"/>
                            <van-icon color="white" style="margin:auto;font-size:larger;width:50%;text-align:left">
                                {{items.mealName}}
                            </van-icon>
                        </div>
                        <div style="width:70%;">
                            <div style="margin:auto;font-size: 24px;">{{'有效天数:' + items.effectiveDays}}</div>
                            <div style="margin:auto;font-size: 24px;">{{'价格:' + items.price}}</div>
                        </div>
                    </div>
                </van-col>
            </van-row>
            <van-row class="ds" style="margin:0px 8px 8px 8px;text-align:center;">
                <van-button round block type="info" @click="onValueSubmit">升级</van-button>
            </van-row>
        </div>
    </van-pull-refresh>
</template>

<script>
    import Vue from "vue";
    import {Toast, NoticeBar} from "vant";

    Vue.use(Toast);
    Vue.use(NoticeBar);
    export default {
        name: "parkCarVIPRecharge",
        data() {
            return {
                isLoading: false,
                noticeText: '',
                mealID: '',
                merchantID: '',
                plateNO: "",
                userTypeName: "",
                selectPackage: null,
                carPackageInfo: [/*{
                    discountType: 1,
                    couponName: 'VIP会员',
                    discountValue: 30
                }, {
                    discountType: 2,
                    couponName: '黄金VIP',
                    discountValue: 90
                }, {
                    discountType: 3,
                    couponName: '白金VIP',
                    discountValue: 365
                }*/],
                cAPPID: '',
                cAppSecret: '',
                cmch_id: '',
                weChatKey: ''
            }
        },
        mounted() {
            let payParameters = localStorage.getItem('payParameters')
            let payParametersJSON = payParameters && JSON.parse(payParameters)
            console.log(payParametersJSON)
            this.cAPPID = payParametersJSON.weChatAppID;
            this.cAppSecret = payParametersJSON.weChatKey; //支付密钥
            this.cmch_id = payParametersJSON.weChatMchID;
            this.weChatKey = payParametersJSON.weChatKey;
            this.merchantID = this.$route.query.MerchantID;
            this.plateNO = this.$route.query.plateNO;
            this.userTypeName = this.$route.query.UserTypeName;
            console.log(this.plateNO)
            this.queryParkConfig();
            this.queryDiscountCoupon();
        },
        methods: {
            queryParkConfig() {
                this.$axios.request({
                    url: "/api/v1/OpenApi/GetParkConfigInfo",
                    method: "get",
                    params: {
                        MerchantID: this.merchantID,
                    },
                }).then((res) => {
                    console.log('queryParkConfig');
                    console.log(res);
                    if (res.data.success == true) {
                        this.noticeText = res.data.models[0].userNotice;
                    } else {
                        this.noticeText = '';
                        Toast.fail(res.data.message);
                    }
                });
            },
            onRefresh() {
                this.queryParkConfig();
                this.queryDiscountCoupon();
                setTimeout(() => {
                    Toast.success('刷新完成');
                    this.isLoading = false;
                }, 1000);
            },
            getCarPackage(item) {
                this.carPackageInfo.forEach(couponItem => {
                    couponItem.isSelect = false;
                });
                item.isSelect = true;
                this.selectPackage = item;
            },
            queryDiscountCoupon() {
                this.$axios
                    .request({
                        url: "/api/v1/OpenApi/GetMealInfo",
                        methods: "get",
                        params: {
                            MerchantID: this.merchantID,
                            MealName: ''
                        },
                    })
                    .then((res) => {
                        // this.$EventBus.$emit('asd',res)
                        console.log(res.data);
                        let carPackageInfo = []
                        if (res.data.success) {
                            res.data.models.forEach(couponItem => {
                                if (couponItem.isNomal == 1 && couponItem.effectiveTypeName == this.userTypeName) {
                                    carPackageInfo.push({...couponItem, isSelect: false});
                                }
                            })
                            console.log(res.data.models)
                        }
                        this.carPackageInfo = carPackageInfo;
                    })
            },
            onValueSubmit(values) {
                console.log(values);
                if (this.selectPackage != null) {
                    let obj = {
                        orderNo: this.guid(),
                        amount: this.selectPackage.price,
                        orderTime: this.dateFormat(),
                    }
                    this.createWXOrder(obj)
                } else
                    Toast.fail('请选择升级套餐');
            },
            createWXOrder(nobj) {
                console.log(nobj)
                this.loading = true;
                this.title = '生成微信订单'
                let that = this;
                let local = 'http://43.138.48.29/token/wxresult';
                let obj = {
                    appid: this.cAPPID,
                    mch_id: this.cmch_id,
                    key: this.cAppSecret,
                    openid: localStorage.getItem('openid'),
                    timestamp: nobj.orderTime,
                    out_trade_no: nobj.orderNo,
                    total_fee: nobj.amount * 100, //写死
                    nonceStr: this.randomString(16),
                    // body: 'JSAPI支付',
                    body: '会员升级' + nobj.amount,

                    notify_url: encodeURIComponent(local), //异步回调地址，去通知服务器的地址
                }
                this.$axios.request({
                    url: window.g.tokenBaseUrl + "/testwxpay",
                    method: "POST",
                    data: {
                        ...obj
                    },
                })
                    .then((res) => {
                        // this.$EventBus.$emit('asd',res)

                        console.log(res.data.success);

                        //发起支付
                        function onBridgeReady(res) {
                            // 参与签名的参数为：appId、timeStamp、nonceStr、package、signType，参数区分大小写。）
                            let paySign = that.$md5(`appId=${that.cAPPID}&nonceStr=${res.nonceStr}&package=${res.package}&signType=MD5&timeStamp=${res.timeStamp}&key=${that.weChatKey}`).toUpperCase();//JCA78956859862659565232696252598
                            console.log(`appId=${that.cAPPID}&nonceStr=${res.nonceStr}&package=${res.package}&signType=MD5&timeStamp=${res.timeStamp}&key=${that.weChatKey}`)//JCA78956859862659565232696252598
                            console.log(paySign)
                            console.log({
                                "appId": that.cAPPID,     //公众号ID，由商户传入
                                "timeStamp": res.timeStamp,         //时间戳，自1970年以来的秒数
                                "nonceStr": res.nonceStr, //随机串
                                "package": res.package,
                                "signType": "MD5",         //微信签名方式：
                                "paySign": paySign //微信签名  https://pay.weixin.qq.com/wiki/doc/api/jsapi.php?chapter=7_7&index=6
                            })
                            WeixinJSBridge.invoke(
                                'getBrandWCPayRequest', {
                                    "appId": that.cAPPID,     //公众号ID，由商户传入
                                    "timeStamp": res.timeStamp,         //时间戳，自1970年以来的秒数
                                    "nonceStr": res.nonceStr, //随机串
                                    "package": res.package,
                                    "signType": "MD5",         //微信签名方式：
                                    "paySign": paySign //微信签名  https://pay.weixin.qq.com/wiki/doc/api/jsapi.php?chapter=7_7&index=6
                                },
                                function (resc) {
                                    if (resc.err_msg == "get_brand_wcpay_request:ok") {
                                        // 使用以上方式判断前端返回,微信团队郑重提示：
                                        // 更新预约订单，增加新的人员，更新支付订单
                                        that.UpdateDiscountAdmin()
                                        //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                                    }
                                    if (resc.err_msg == "get_brand_wcpay_request:cancel") {
                                        Toast.fail('支付过程中用户取消');
                                        // 使用以上方式判断前端返回,微信团队郑重提示：
                                        //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                                    }
                                    if (resc.err_msg == "get_brand_wcpay_request:fail") {
                                        Toast.fail('支付失败');
                                        // 使用以上方式判断前端返回,微信团队郑重提示：
                                        //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                                    }
                                    that.value = '';
                                    // Toast('查询订单信息')
                                    // that.testFindOrder(newResc, OrderNo)
                                });
                        }

                        if (typeof WeixinJSBridge == "undefined") {
                            if (document.addEventListener) {
                                document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
                            } else if (document.attachEvent) {
                                document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
                                document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
                            }
                        } else {
                            onBridgeReady(res.data.data);
                        }
                        if (res.data.return_msg == 'OK') {
                            console.log('测试成功,去查询订单')

                        } else {
                            console.log('我暂时无法完成支付')
                        }
                        that.loading = false;


                    }).catch(err => {
                    Toast.fail(err.message);
                });
            },
            randomString(e) {
                e = e || 32;
                var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz",
                    a = t.length,
                    n = "";
                for (i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
                return n
            },
            guid() {
                function S4() {
                    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
                }

                return S4() + S4() + S4() + S4() + S4() + S4();
            },
            dateFormat() {
                var datec = new Date();
                var year = datec.getFullYear();
                /* 在日期格式中，月份是从0开始的，因此要加0
                 * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
                 * */
                var month = datec.getMonth() + 1 < 10 ? "0" + (datec.getMonth() + 1) : datec.getMonth() + 1;
                var day = datec.getDate() < 10 ? "0" + datec.getDate() : datec.getDate();
                var hours = datec.getHours() < 10 ? "0" + datec.getHours() : datec.getHours();
                var minutes = datec.getMinutes() < 10 ? "0" + datec.getMinutes() : datec.getMinutes();
                var seconds = datec.getSeconds() < 10 ? "0" + datec.getSeconds() : datec.getSeconds();
                // 拼接
                return '' + year + month + day + hours + minutes + seconds;
            },
            UpdateDiscountAdmin() {
                console.log(this.merchantID + ' ' + this.adminID);
                this.$axios
                    .request({
                        url: "/api/v1/OpenApi/CarBindMeal",
                        methods: "get",
                        params: {
                            MerchantID: this.merchantID,
                            MealID: this.selectPackage.id,
                            PlateNO: this.plateNO
                        },
                    })
                    .then((res) => {
                        console.log('UpdateDiscountAdmin');
                        console.log(res);
                        if (res.data.success == true) {
                            this.plateNO = '';
                            this.mealID = '';
                            let url = window.location.origin + window.location.pathname + window.location.search + '#/parkFinishInfo?info=会员升级成功';
                            window.location.replace(url)
                        } else {
                            Toast.fail(res.data.message);
                        }
                    });
            }
        }
    }
</script>

<style scoped>

</style>