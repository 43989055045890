<template>
    <div style="height:100vh;background-color:#58B6FC"
         element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <van-row class="ds" style="margin:0px 8px 8px 8px;text-align: center;border-radius:5px;">
            <van-divider
                    :style="{ color: 'white', borderColor: '#1989fa', padding: '0 16px', fontSize: 'large' }">
                {{this.info}}
            </van-divider>
        </van-row>
        <van-row class="ds" style="margin:0px 8px 8px 8px;text-align: center;border-radius:5px;">
            <van-button round block type="info" @click="closePage">完成</van-button>
        </van-row>
    </div>
</template>

<script>
    export default {
        name: "parkFinishInfo",
        data() {
            return {
                info: '',
            }
        },
        mounted() {
            this.info = this.$route.query.info;
        },
        methods: {
            closePage() {
                WeixinJSBridge.call('closeWindow');
            }
        }
    }
</script>

<style scoped>

</style>