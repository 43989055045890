<template>
    <div style="height:100vh;background-color:#58B6FC"
         element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <van-form validate-first>
            <van-row class="ds" style="margin:0px 8px 8px 8px;text-align:center">
                <van-col style="margin-top:8px;display:flex;width:100%;height:50px">
                    <van-tag style="border-radius:5px;width:18%" type="primary" size="large">车牌号码</van-tag>
                    <div style="margin-left:5px;font-size:xx-large;text-align:center;width:10%;border:2px solid gray;background-color:white"
                         @click="showPlateKeyboard = true">{{plateNO[0]}}
                    </div>
                    <div style="margin-left:2px;font-size:xx-large;text-align: center;width:10%;border: 2px solid gray;background-color:white"
                         @click="showPlateKeyboard = true">{{plateNO[1]}}
                    </div>
                    <div style="margin-left:2px;font-size:xx-large;text-align: center;width:10%;border: 2px solid gray;background-color:white"
                         @click="showPlateKeyboard = true">{{plateNO[2]}}
                    </div>
                    <div style="margin-left:2px;font-size:xx-large;text-align: center;width:10%;border: 2px solid gray;background-color:white"
                         @click="showPlateKeyboard = true">{{plateNO[3]}}
                    </div>
                    <div style="margin-left:2px;font-size:xx-large;text-align: center;width:10%;border: 2px solid gray;background-color:white"
                         @click="showPlateKeyboard = true">{{plateNO[4]}}
                    </div>
                    <div style="margin-left:2px;font-size:xx-large;text-align: center;width:10%;border: 2px solid gray;background-color:white"
                         @click="showPlateKeyboard = true">{{plateNO[5]}}
                    </div>
                    <div style="margin-left:2px;font-size:xx-large;text-align: center;width:10%;border: 2px solid gray;background-color:white"
                         @click="showPlateKeyboard = true">{{plateNO[6]}}
                    </div>
                    <div style="margin-left:2px;font-size:xx-large;text-align: center;width:10%;border: 2px solid gray;background-color:white"
                         @click="showPlateKeyboard = true">{{plateNO[7]}}
                    </div>
                </van-col>
            </van-row>
            <van-row class="ds" style="margin:0px 8px 8px 8px;text-align: center;border-radius:5px;">
                <van-col style="border-radius:5px;width:100%">
                    <van-field v-model="phone" name="phone" label="联系电话"
                               :rules="[{ required: true, message: '联系电话不能为空' }]"/>
                </van-col>
            </van-row>
            <van-row class="ds" style="margin:0px 8px 8px 8px;text-align:center;">
                <van-button round block type="info" @click="onIssueSubmit">注册</van-button>
            </van-row>
            <van-row class="ds" style="margin:0px 8px 8px 8px;text-align:center;">
                <van-button round block type="info" @click="onSubmit">返回</van-button>
            </van-row>
        </van-form>
        <van-popup style="background-color:white" v-model:show="showPlateKeyboard" position="bottom">
            <div v-if="plateNO.length == 0" style="margin:6px;text-align: center">
                <van-row v-for="(items, dpindex) in dipartmentKey" class="ds">
                    <van-col v-for="(item, index) in items" style="width:10%;">
                        <div round block
                             style="margin-left:2px;font-size:xx-large;text-align: center;border-radius:5px;border: 1px solid gray;background-color: #1989fa"
                             @click="clickWordKey(item)">{{item}}
                        </div>
                    </van-col>
                </van-row>
            </div>
            <div v-else style="margin:6px;text-align: center">
                <van-row v-for="(items, windex) in wordKey" class="ds">
                    <van-col v-for="(item, index) in items" style="width:10%;">
                        <div v-if="item == '<'" round block
                             style="margin:2px;font-size:xx-large;text-align: center;border-radius:5px;border: 1px solid gray;background-color: #1989fa"
                             @click="clickWordKey(item)">
                            <i class="el-icon-back"></i>
                        </div>
                        <div v-else round block
                             style="margin:2px;font-size:xx-large;text-align: center;border-radius:5px;border: 1px solid gray;background-color: #1989fa"
                             @click="clickWordKey(item)">{{item}}
                        </div>
                    </van-col>
                </van-row>
            </div>
        </van-popup>
    </div>
</template>

<script>
    import {Toast} from "vant";

    export default {
        name: "parkCarIssue",
        data() {
            return {
                merchantID: '',
                userTypeName: '',
                showPlateKeyboard: false,
                plateNO: "",
                phone: '',
                dipartmentKey: [['粤', '京', '津', '沪', '渝', '宁', '藏', '桂', '新', '蒙'],
                    ['黑', '滇', '云', '吉', '皖', '鲁', '晋', '桂', '闽', '青'],
                    ['苏', '赣', '冀', '豫', '浙', '琼', '鄂', '湘', '甘', '陇'],
                    ['川', '黔', '贵', '辽', '陕', '秦', '军', '使']],
                wordKey: [['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
                    ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K'],
                    ['L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V'],
                    ['W', 'X', 'Y', 'Z', '学', '警', '港', '澳', '挂', '<']]
            }
        },
        mounted() {
            this.merchantID = this.$route.query.MerchantID;
            this.plateNO = this.$route.query.plateNO;
            this.userTypeName = this.$route.query.UserTypeName;
        },
        methods: {
            clickWordKey(item) {
                if (item == '<')
                    this.plateNO = this.plateNO.substr(0, this.plateNO.length - 1);
                else if (item == '关闭')
                    this.showPlateKeyboard = false;
                else if (this.plateNO.length <= 8)
                    this.plateNO += item;
            },
            onIssueSubmit() {
                if (this.plateNO.length < 7 || this.phone == '') {
                    Toast.fail('请输入车牌号码和手机号');
                } else {
                    let dateTimeNow = new Date();
                    let effectiveBegin = dateTimeNow.getFullYear() + '-' + (dateTimeNow.getMonth() + 1) + '-' + dateTimeNow.getDate() + ' 00:00:00';
                    let effectiveEnd = dateTimeNow.getFullYear() + '-' + (dateTimeNow.getMonth() + 1) + '-' + dateTimeNow.getDate() + ' 00:00:00';
                    this.$axios.request({
                        url: "/api/v1/OpenApi/ParkCarChange",
                        method: "POST",
                        data: {
                            plateNO: this.plateNO,
                            isBlack: 0,
                            effectiveBegin: effectiveBegin,
                            effectiveEnd: effectiveEnd,
                            errorPlateNO: '',
                            userID: 0,
                            roadName: '',
                            userTypeName: this.userTypeName,
                            adminName: '会员注册',
                            userValue: 0,
                            userName: '',
                            plateColor: '蓝色',
                            carType: '小型车',
                            phone: this.phone,
                            carColor: '白色',
                            fee: 0,
                            syncType: 0,
                            changeType: 3,
                            MerchantID: this.merchantID,
                            parkID: '',
                            createBy: '',
                        },
                    }).then((res) => {
                        console.log('carInfoConfirm');
                        console.log(res);
                        if (res.data.success == true) {
                            let url = window.location.origin + window.location.pathname + window.location.search + '#/parkCarVIPRecharge?MerchantID=' + this.merchantID + '&plateNO=' + this.plateNO + '&UserTypeName=' + this.userTypeName;;
                            window.location.replace(url)
                        } else {
                            Toast.fail(res.data.message);
                        }
                    });
                }
            },
            onSubmit() {
                let url = window.location.origin + window.location.pathname + window.location.search + '#/parkCarLogin?MerchantID=' + this.merchantID;
                window.location.replace(url)
            }
        }
    }
</script>

<style scoped>

</style>