<template>
    <van-pull-refresh
            v-model="isLoading"
            success-text="刷新成功"
            @refresh="onRefresh">
        <van-notice-bar
                v-if="noticeText != '' && noticeText != null"
                left-icon="volume-o"
                :text="noticeText"
        />
        <div style="height:100vh;background-color:#58B6FC"
             element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
            <van-tabs>
                <van-tab title="优惠发放">
                    <van-form validate-first @submit="onSubmit" style="margin-top:8px;">
                        <van-row class="ds" style="margin:0px 8px 8px 8px;text-align:center">
                            <van-col style="display:flex;width:100%;height:50px">
                                <van-tag style="border-radius:5px;width:18%" type="primary" size="large">车牌号码</van-tag>
                                <div style="margin-left:5px;font-size:xx-large;text-align:center;width:10%;border:2px solid gray;background-color:white"
                                     @click="showPlateKeyboard = true">{{plateNO[0]}}
                                </div>
                                <div style="margin-left:2px;font-size:xx-large;text-align: center;width:10%;border: 2px solid gray;background-color:white"
                                     @click="showPlateKeyboard = true">{{plateNO[1]}}
                                </div>
                                <div style="margin-left:2px;font-size:xx-large;text-align: center;width:10%;border: 2px solid gray;background-color:white"
                                     @click="showPlateKeyboard = true">{{plateNO[2]}}
                                </div>
                                <div style="margin-left:2px;font-size:xx-large;text-align: center;width:10%;border: 2px solid gray;background-color:white"
                                     @click="showPlateKeyboard = true">{{plateNO[3]}}
                                </div>
                                <div style="margin-left:2px;font-size:xx-large;text-align: center;width:10%;border: 2px solid gray;background-color:white"
                                     @click="showPlateKeyboard = true">{{plateNO[4]}}
                                </div>
                                <div style="margin-left:2px;font-size:xx-large;text-align: center;width:10%;border: 2px solid gray;background-color:white"
                                     @click="showPlateKeyboard = true">{{plateNO[5]}}
                                </div>
                                <div style="margin-left:2px;font-size:xx-large;text-align: center;width:10%;border: 2px solid gray;background-color:white"
                                     @click="showPlateKeyboard = true">{{plateNO[6]}}
                                </div>
                                <div style="margin-left:2px;font-size:xx-large;text-align: center;width:10%;border: 2px solid gray;background-color:white"
                                     @click="showPlateKeyboard = true">{{plateNO[7]}}
                                </div>
                            </van-col>
                        </van-row>
                        <van-row v-if="false" class="ds" style="margin:0px 8px 8px 8px;text-align:center">
                            <van-col style="border-radius:5px;width:100%">
                                <van-radio-group v-model="discountType" direction="horizontal">
                                    <van-radio name="0">折扣金额</van-radio>
                                    <van-radio name="1">减免时间</van-radio>
                                </van-radio-group>
                            </van-col>
                        </van-row>
                        <van-row class="ds" style="margin:0px 8px 8px 8px;text-align: center;">
                            <van-col style="border-radius:5px;width:100%;display:flex;">
                                <van-tag v-if="discountType == 0" style="border-radius:5px;width:16%" type="primary"
                                         size="large">折扣金额
                                </van-tag>
                                <van-tag v-else style="border-radius:5px;width:16%" type="primary" size="large">减免时间
                                </van-tag>
                                <van-stepper v-model="discountValue" style="margin-left:5px;" input-width="100px"
                                             integer/>
                            </van-col>
                        </van-row>
                        <van-row class="ds" style="margin:0px 8px 8px 8px;text-align: center;">
                            <van-button round block type="info" native-type="submit">提交</van-button>
                        </van-row>
                    </van-form>
                    <van-popup style="background-color:white" v-model:show="showPlateKeyboard" position="bottom">
                        <div v-if="plateNO.length == 0" style="margin:6px;text-align: center">
                            <van-row v-for="(items, dpindex) in dipartmentKey" class="ds">
                                <van-col v-for="(item, index) in items" style="width:10%;">
                                    <div round block
                                         style="margin:2px;font-size:xx-large;text-align: center;border-radius:5px;border: 1px solid gray;background-color:#1989fa"
                                         @click="clickWordKey(item)">{{item}}
                                    </div>
                                </van-col>
                            </van-row>
                        </div>
                        <div v-else style="margin:6px;text-align:center;">
                            <van-row v-for="(items, windex) in wordKey" class="ds">
                                <van-col v-for="(item, index) in items" style="width:10%;">
                                    <div v-if="item == '<'" round block
                                         style="margin:2px;font-size:xx-large;text-align: center;border-radius:5px;border: 1px solid gray;background-color: #1989fa"
                                         @click="clickWordKey(item)">
                                        <i class="el-icon-back"></i>
                                    </div>
                                    <div v-else round block
                                         style="margin:2px;font-size:xx-large;text-align: center;border-radius:5px;border: 1px solid gray;background-color: #1989fa"
                                         @click="clickWordKey(item)">{{item}}
                                    </div>
                                </van-col>
                            </van-row>
                        </div>
                    </van-popup>
                </van-tab>
                <van-tab title="优惠领取">
                    <van-row v-for="(items, index) in couponInfo" class="ds"
                             style="margin:4px;text-align: center">
                        <van-col v-if="items.qcodeType != 0"
                                 style="width:100%;border: 2px solid gray;border-radius:5px;background-color:white"
                                 @click="getQRCode(items)">
                            <van-tag style="margin:4px;" type="primary" size="large">{{items.couponName}}</van-tag>
                            <div style="text-align:center">{{'优惠额:' + items.discountValue}}</div>
                        </van-col>
                    </van-row>
                    <van-popup style="background-color:white;padding:64px" v-model:show="showQRCode">
                        <div id="qrcodeEle"
                             style='display:flex;justify-content: center;padding:10px 0;width:90%;margin-left:5%;'></div>
                    </van-popup>
                </van-tab>
                <van-tab title="商户信息">
                    <span style="border-radius:5px;font-size:large;color:white">商家名称:{{adminName}}  商家金额:{{adminFreeMoney}}</span>
                    <van-form validate-first @submit="onEditAdminName" style="margin-top:8px;">
                        <van-row class="ds" style="margin:0px 8px 8px 8px;text-align: center;">
                            <van-col style="border-radius:5px;width:88%;display:flex;">
                                <van-field v-model="editAdminName" label="商户名称" placeholder="请输入商户名称"/>
                            </van-col>
                            <van-col style="border-radius:5px;width:12%;display:flex;">
                                <van-button round block type="info" native-type="submit">
                                    <van-icon name="edit"/>
                                </van-button>
                            </van-col>
                        </van-row>
                    </van-form>
                    <van-form validate-first @submit="onEditAdminPassWord" style="margin-top:8px;">
                        <van-row class="ds" style="margin:0px 8px 8px 8px;text-align: center;">
                            <van-col style="border-radius:5px;width:88%;display:flex;">
                                <van-field v-model="passWord" type="password" label="账户密码" placeholder="请输入新密码"/>
                            </van-col>
                            <van-col style="border-radius:5px;width:12%;display:flex;">
                                <van-button round block type="info" native-type="submit">
                                    <van-icon name="edit"/>
                                </van-button>
                            </van-col>
                        </van-row>
                    </van-form>
                    <van-form validate-first @submit="onValueSubmit" style="margin-top:8px;">
                        <van-row class="ds" style="margin:0px 8px 8px 8px;text-align: center;">
                            <van-col style="border-radius:5px;width:100%;display:flex;">
                                <van-tag v-if="valueType == 0" style="border-radius:5px;width:16%" type="primary"
                                         size="large">充值金额
                                </van-tag>
                                <van-tag v-else style="border-radius:5px;width:16%" type="primary" size="large">充值时间
                                </van-tag>
                                <van-stepper v-model="addValue" style="margin-left:5px;" input-width="100px" integer
                                             @change="changeAddValue"/>
                            </van-col>
                        </van-row>
                        <van-row class="ds" style="margin:0px 8px 8px 8px;text-align: center;">
                            <van-col v-for="(item, index) in rechargeConfig">
                                <div round block
                                     style="margin:2px;font-size:xx-large;text-align:center;border-radius:5px;border:1px solid gray;background-color:#1989fa;width:80px"
                                     @click="clickFreeKey(item)">{{item.freeStart}}
                                </div>
                            </van-col>
                        </van-row>
                        <span style="margin:8px;color:white;width: 100%">{{addRealValue}}</span>
                        <van-row class="ds" style="margin:0px 8px 8px 8px;text-align: center;">
                            <van-button round block type="info" native-type="submit">提交</van-button>
                        </van-row>
                    </van-form>
                </van-tab>
            </van-tabs>
        </div>
    </van-pull-refresh>
</template>

<script>
    import QRCode from 'qrcodejs2'
    import Vue from 'vue';
    import {Tab, Tabs, Form, Col, Row, Field, Toast, Popup, Tag, RadioGroup, Radio, PullRefresh, NoticeBar} from 'vant';

    Vue.use(Tag);
    Vue.use(Field);
    Vue.use(Toast);
    Vue.use(RadioGroup);
    Vue.use(Radio);
    Vue.use(Tab);
    Vue.use(Tabs);
    Vue.use(Col);
    Vue.use(Row);
    Vue.use(Form);
    Vue.use(Popup);
    Vue.use(PullRefresh);
    Vue.use(NoticeBar);
    export default {
        name: "parkDiscountCar",
        data() {
            return {
                noticeText: '',
                isLoading: false,
                merchantNo: '',
                discountId: '',
                adminID: '',
                adminName: '',
                editAdminName: '',
                passWord: '',
                merchantID: '',
                valueType: 0,
                addValue: 0,
                addRealValue: '',
                showQRCode: false,
                showPlateKeyboard: false,
                discountType: 0,
                plateNO: "",
                discountValue: 0,
                dipartmentKey: [['粤', '京', '津', '沪', '渝', '宁', '藏', '桂', '新', '蒙'],
                    ['黑', '滇', '云', '吉', '皖', '鲁', '晋', '桂', '闽', '青'],
                    ['苏', '赣', '冀', '豫', '浙', '琼', '鄂', '湘', '甘', '陇'],
                    ['川', '黔', '贵', '辽', '陕', '秦', '军', '使']],
                wordKey: [['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
                    ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K'],
                    ['L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V'],
                    ['W', 'X', 'Y', 'Z', '学', '警', '港', '澳', '挂', '<']],
                couponInfo: [/*{
                    discountType: 0,
                    couponName: '最多优惠30元',
                    discountValue: 30
                }, {
                    discountType: 0,
                    couponName: '最多优惠10元',
                    discountValue: 10
                }, {
                    discountType: 0,
                    couponName: '最多优惠20元',
                    discountValue: 20
                }*/],
                cAPPID: '',
                cAppSecret: '',
                cmch_id: '',
                weChatKey: '',
                rechargeConfig: [],
                adminFreeMoney: 0
            }
        },
        mounted() {
            let payParameters = localStorage.getItem('payParameters')
            let payParametersJSON = payParameters && JSON.parse(payParameters)
            console.log(payParametersJSON)
            this.cAPPID = payParametersJSON.weChatAppID;
            this.cAppSecret = payParametersJSON.weChatKey; //支付密钥
            this.cmch_id = payParametersJSON.weChatMchID;
            this.weChatKey = payParametersJSON.weChatKey;
            this.merchantID = this.$route.query.merchantID;
            this.discountId = this.$route.query.id;
            this.adminID = this.$route.query.adminID;
            this.adminName = this.$route.query.adminName;
            this.merchantNo = this.$route.query.merchantNo;
            console.log(this.merchantID + ' 2212121  ' + this.adminID);
            //let path = window.location.href.split("?")
            //console.log(path);
            this.queryDiscountAdmin();
            this.queryParkConfig();
            this.queryDiscountCoupon();
            this.GetParkDiscountFree();
        },
        methods: {
            queryParkConfig() {
                this.$axios.request({
                    url: "/api/v1/OpenApi/GetParkConfigInfo",
                    method: "get",
                    params: {
                        MerchantID: this.merchantID,
                    },
                }).then((res) => {
                    console.log('queryParkConfig');
                    console.log(res);
                    if (res.data.success == true) {
                        this.noticeText = res.data.models[0].disCountAdminNotice;
                    } else {
                        this.noticeText = '';
                        Toast.fail(res.data.message);
                    }
                });
            },
            onRefresh() {
                this.queryDiscountAdmin();
                this.queryParkConfig();
                this.queryDiscountCoupon();
                this.GetParkDiscountFree();
                setTimeout(() => {
                    Toast.success('刷新完成');
                    this.isLoading = false;
                }, 1000);
            },
            GetParkDiscountFree() {
                this.$axios
                    .request({
                        url: "/api/v1/OpenApi/GetParkDiscountFree",
                        methods: "get",
                        params: {
                            MerchantID: this.merchantID
                        },
                    })
                    .then((res) => {
                        console.log('GetParkDiscountFree');
                        console.log(res);
                        if (res.data.success == true) {
                            this.rechargeConfig = res.data.models;
                        } else {
                            this.rechargeConfig = [];
                            Toast.fail(res.data.message);
                        }
                    });
            },
            queryDiscountAdmin() {
                this.$axios
                    .request({
                        url: "/api/v1/OpenApi/GetParkDiscountAdmin",
                        methods: "get",
                        params: {
                            MerchantID: this.merchantID,
                            StartIndex: 0,
                            EndIndex: 1,
                            AdminName: '',
                            AdminID: this.adminID
                        },
                    })
                    .then((res) => {
                        console.log('queryDiscountAdmin');
                        console.log(res);
                        if (res.data.success == true) {
                            this.adminFreeMoney = res.data.models[0].freeMoney;
                        } else {
                            this.adminFreeMoney = 0;
                            Toast.fail(res.data.message);
                        }
                    });
            },
            queryDiscountCoupon() {
                this.$axios
                    .request({
                        url: "/api/v1/OpenApi/GetParkCouponInfo",
                        methods: "get",
                        params: {
                            MerchantID: this.merchantID,
                            couponName: '',
                            couponType: -1,
                            qcodeType: -1
                        },
                    })
                    .then((res) => {
                        console.log('queryDiscountCoupon');
                        console.log(res);
                        if (res.data.success == true) {
                            this.couponInfo = res.data.models.filter(couponItem => {
                                return couponItem.couponType == 0;
                            });
                            //this.total = res.data.recordCount;
                            //this.couponInfo = res.data.models;
                        } else {
                            this.couponInfo = [];
                            Toast.fail(res.data.message);
                        }
                    });
            },
            clickFreeKey(item) {
                this.addValue = item.freeStart;
            },
            changeAddValue() {
                this.addRealValue = '';
                let rechargeConfig = this.rechargeConfig.filter(item => item.freeStart <= this.addValue && item.freeEnd > this.addValue);
                if (rechargeConfig.length > 0) {
                    if (rechargeConfig[0].freeType == 0) {
                        this.addRealValue = '支付金额:' + this.addValue + ' 实际到账金额:' + (this.addValue + rechargeConfig[0].freeValue);
                    } else {
                        this.addRealValue = '支付金额:' + (this.addValue * rechargeConfig[0].freeValue / 100) + ' 实际到账金额:' + this.addValue;
                    }
                } else {
                    this.addRealValue = '支付金额:' + this.addValue + ' 实际到账金额:' + this.addValue;
                }
            },
            getQRCode(item) {
                let timeNowTicks = new Date().getTime();
                let qcodeValue = item.qcodeType == 2 ? timeNowTicks : '604252403000';
                let qRCodeUrl = window.g.qrcodeLike + "#/parkQRCodeCoupon?MerchantID=" + this.merchantID + "&DiscountType=" + item.discountType + "&DiscountValue=" + item.discountValue + "&id=" + this.discountId + "&adminID=" + this.adminID + "&adminName=" + this.adminName + "&qcodeValue=" + qcodeValue + "&couponID=" + item.id + "&couponType=" + item.couponType
                console.log(qRCodeUrl);
                this.showQRCode = true;
                this.$nextTick(() => {
                    var qrcodeElement = document.getElementById('qrcodeEle');
                    qrcodeElement.innerHTML = '';
                    new QRCode('qrcodeEle', {
                        text: qRCodeUrl,
                        width: 256,
                        height: 256,
                        colorDark: '#000000',
                        colorLight: '#ffffff',
                        correctLevel: QRCode.CorrectLevel.H
                    });
                });
            },
            clickWordKey(item) {
                if (item == '<')
                    this.plateNO = this.plateNO.substr(0, this.plateNO.length - 1);
                else if (item == '关闭')
                    this.showPlateKeyboard = false;
                else if (this.plateNO.length <= 8)
                    this.plateNO += item;
            },
            onSubmit(values) {
                console.log(values);
                console.log(this.discountValue);
                this.$axios.request({
                    url: "/api/v1/OpenApi/ParkDiscountPlate",
                    method: "POST",
                    data: {
                        MerchantID: this.merchantID,
                        createBy: this.discountId,
                        id: this.discountId,
                        storeCode: this.adminID,
                        storeName: this.adminName,
                        plateNO: this.plateNO,
                        type: this.discountType,
                        value: this.discountValue,
                        discountID: new Date().getTime(),
                        fixQRCode: 0,
                        discountPrice: 0,
                        discountTime: 0,
                        discountPercent: 0
                    },
                }).then((res) => {
                    console.log('onSubmit');
                    console.log(res);
                    if (res.data.success == true) {
                        this.plateNO = '';
                        this.discountType = 0;
                        this.discountValue = 0;
                        Toast.success(res.data.message);
                    } else {
                        Toast.fail(res.data.message);
                        //this.$message.error(res.data.message);
                    }
                });
            },
            onEditAdminName() {
                if (this.editAdminName == '')
                    Toast.fail('商户名称不能为空');
                else {
                    this.$axios.request({
                        url: "/api/v1/OpenApi/WriteParkDiscountAdmin",
                        method: "POST",
                        data: {
                            MerchantID: this.merchantID,
                            id: this.discountId,
                            adminName: this.editAdminName,
                            syncType: 1
                        },
                    }).then((res) => {
                        console.log('onEditAdminName');
                        console.log(res);
                        if (res.data.success == true) {
                            this.adminName = this.editAdminName;
                            this.editAdminName = '';
                            Toast.success('修改商户成功');
                        } else {
                            Toast.fail(res.data.message);
                        }
                    });
                }
            },
            onEditAdminPassWord() {
                if (this.passWord == '')
                    Toast.fail('新密码不能为空');
                else {
                    this.$axios.request({
                        url: "/api/v1/OpenApi/WriteParkDiscountAdmin",
                        method: "POST",
                        data: {
                            MerchantID: this.merchantID,
                            id: this.discountId,
                            passWord: this.passWord,
                            syncType: 3
                        },
                    }).then((res) => {
                        console.log('onEditAdminPassWord');
                        console.log(res);
                        if (res.data.success == true) {
                            this.passWord = '';
                            Toast.success('修改密码成功');
                        } else {
                            Toast.fail(res.data.message);
                        }
                    });
                }
            },
            onValueSubmit(values) {
                console.log(values);
                if (this.addValue > 0) {
                    let payValue = this.addValue;
                    let realValue = this.addValue;
                    let rechargeConfig = this.rechargeConfig.filter(item => item.freeStart <= this.addValue && item.freeEnd > this.addValue);
                    if (rechargeConfig.length > 0) {
                        if (rechargeConfig[0].freeType == 0) {
                            realValue = this.addValue + rechargeConfig[0].freeValue;
                        } else {
                            payValue = this.addValue * rechargeConfig[0].freeValue / 100;
                        }
                    }
                    let obj = {
                        orderNo: this.guid(),
                        amount: payValue,//this.addValue,
                        realValue: realValue,
                        orderTime: this.dateFormat(), //yyyyMMddHHmmss
                    }
                    //this.UpdateDiscountAdmin(obj);
                    this.createWXOrder(obj)
                } else
                    Toast.fail('充值金额不能小于等于0');
            },
            createWXOrder(nobj) {
                // Toast('第二步，去node服务器生成微信订单，去支付')
                // this.$toast.loading({
                //     message: '第二步，去node服务器生成微信订单，去支付',
                //     forbidClick: true,
                // });
                console.log(nobj)
                //Toast.fail(nobj.orderNo + ' ' + nobj.amount + ' ' +nobj.orderTime);//
                //Toast.fail(localStorage.getItem('openid'));
                this.loading = true;
                this.title = '生成微信订单'
                let that = this;
                let local = 'http://43.138.48.29/token/wxresult';
                let obj = {
                    appid: this.cAPPID,
                    mch_id: this.cmch_id,
                    key: this.cAppSecret,
                    // mch_id:'1305638280',
                    openid: localStorage.getItem('openid'),
                    timestamp: nobj.orderTime,
                    out_trade_no: nobj.orderNo,
                    total_fee: nobj.amount * 100, //写死
                    nonceStr: this.randomString(16),
                    // body: 'JSAPI支付',
                    body: '商户充值' + nobj.amount,

                    notify_url: encodeURIComponent(local), //异步回调地址，去通知服务器的地址
                    // notify_url: local
                }
                //Toast.fail(this.weChatKey);
                // Toast('正在生成微信订单')
                // this.$toast.loading({
                //     message: '正在生成微信订单',
                //     forbidClick: true,
                // });
                this.$axios.request({
                    url: window.g.tokenBaseUrl + "/testwxpay",
                    method: "POST",
                    data: {
                        ...obj
                    },
                })
                    .then((res) => {
                        // this.$EventBus.$emit('asd',res)

                        console.log(res.data.success);

                        //发起支付
                        function onBridgeReady(res) {
                            // alert(JSON.stringify(res))
                            // 参与签名的参数为：appId、timeStamp、nonceStr、package、signType，参数区分大小写。）
                            let paySign = that.$md5(`appId=${that.cAPPID}&nonceStr=${res.nonceStr}&package=${res.package}&signType=MD5&timeStamp=${res.timeStamp}&key=${that.weChatKey}`).toUpperCase();//JCA78956859862659565232696252598
                            console.log(`appId=${that.cAPPID}&nonceStr=${res.nonceStr}&package=${res.package}&signType=MD5&timeStamp=${res.timeStamp}&key=${that.weChatKey}`)//JCA78956859862659565232696252598
                            console.log(paySign)
                            console.log({
                                "appId": that.cAPPID,     //公众号ID，由商户传入
                                "timeStamp": res.timeStamp,         //时间戳，自1970年以来的秒数
                                "nonceStr": res.nonceStr, //随机串
                                "package": res.package,
                                "signType": "MD5",         //微信签名方式：
                                "paySign": paySign //微信签名  https://pay.weixin.qq.com/wiki/doc/api/jsapi.php?chapter=7_7&index=6
                            })
                            WeixinJSBridge.invoke(
                                'getBrandWCPayRequest', {
                                    "appId": that.cAPPID,     //公众号ID，由商户传入
                                    "timeStamp": res.timeStamp,         //时间戳，自1970年以来的秒数
                                    "nonceStr": res.nonceStr, //随机串
                                    "package": res.package,
                                    "signType": "MD5",         //微信签名方式：
                                    "paySign": paySign //微信签名  https://pay.weixin.qq.com/wiki/doc/api/jsapi.php?chapter=7_7&index=6
                                },
                                function (resc) {
                                    // that.updateotherxx(res);
                                    // alert(JSON.stringify(resc))
                                    if (resc.err_msg == "get_brand_wcpay_request:ok") {
                                        // 使用以上方式判断前端返回,微信团队郑重提示：
                                        // 更新预约订单，增加新的人员，更新支付订单
                                        that.UpdateDiscountAdmin(nobj)
                                        //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                                    }
                                    if (resc.err_msg == "get_brand_wcpay_request:cancel") {
                                        // alert('支付过程中用户取消')
                                        //this.$message({
                                        //    message: '支付过程中用户取消',
                                        //    type: 'warning'
                                        //});
                                        Toast.fail('支付过程中用户取消');
                                        // 使用以上方式判断前端返回,微信团队郑重提示：
                                        //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                                    }
                                    if (resc.err_msg == "get_brand_wcpay_request:fail") {
                                        // alert('支付失败')
                                        //this.$message.error('支付失败');
                                        Toast.fail('支付失败');
                                        // 使用以上方式判断前端返回,微信团队郑重提示：
                                        //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                                    }
                                    that.value = '';
                                    // Toast('查询订单信息')
                                    // that.testFindOrder(newResc, OrderNo)
                                });
                        }

                        if (typeof WeixinJSBridge == "undefined") {
                            if (document.addEventListener) {
                                document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
                            } else if (document.attachEvent) {
                                document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
                                document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
                            }
                        } else {
                            onBridgeReady(res.data.data);
                        }
                        if (res.data.return_msg == 'OK') {
                            console.log('测试成功,去查询订单')
                            // this.wxpayFunc(res, OrderNo, 5.51 * 100);

                        } else {
                            console.log('我暂时无法完成支付')
                            // this.$message.error(res.data.message);
                        }
                        that.loading = false;


                    }).catch(err => {
                    Toast.fail(err.message);
                });
            },
            randomString(e) {
                e = e || 32;
                var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz",
                    a = t.length,
                    n = "";
                for (i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
                return n
            },
            guid() {
                function S4() {
                    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
                }

                return S4() + S4() + S4() + S4() + S4() + S4();
            },
            dateFormat() {
                var datec = new Date();
                var year = datec.getFullYear();
                /* 在日期格式中，月份是从0开始的，因此要加0
                 * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
                 * */
                var month = datec.getMonth() + 1 < 10 ? "0" + (datec.getMonth() + 1) : datec.getMonth() + 1;
                var day = datec.getDate() < 10 ? "0" + datec.getDate() : datec.getDate();
                var hours = datec.getHours() < 10 ? "0" + datec.getHours() : datec.getHours();
                var minutes = datec.getMinutes() < 10 ? "0" + datec.getMinutes() : datec.getMinutes();
                var seconds = datec.getSeconds() < 10 ? "0" + datec.getSeconds() : datec.getSeconds();
                // 拼接
                return '' + year + month + day + hours + minutes + seconds;
            },
            UpdateDiscountAdmin(obj) {
                this.$axios
                    .request({
                        url: "/api/v1/OpenApi/GetDiscountAdmin",
                        methods: "get",
                        params: {
                            MerchantID: this.merchantID,
                            AdminID: this.adminID
                        },
                    })
                    .then((res) => {
                        console.log('GetDiscountAdmin');
                        console.log(res);
                        if (res.data.success == true) {
                            let orgFreeMoney = res.data.freeMoney;
                            let orgFreeTime = res.data.freeTime;
                            let freeMoney = Number(orgFreeMoney) + obj.realValue;//Number(this.addValue);
                            let freeTime = orgFreeTime;
                            console.log(orgFreeMoney + ' ' + freeMoney);
                            this.$axios.request({
                                url: "/api/v1/OpenApi/ParkDiscountAdminChange",
                                method: "POST",
                                data: {
                                    MerchantID: this.merchantID,
                                    createBy: this.discountId,
                                    id: this.discountId,
                                    operationAdminID: '',
                                    operationAdminName: '',
                                    adminID: this.adminID,
                                    adminName: this.adminName,
                                    endDateTime: '',
                                    orgEndDateTime: '',
                                    orgFreeMoney: orgFreeMoney,
                                    orgFreeTime: orgFreeTime,
                                    freeMoney: freeMoney,
                                    freeTime: freeTime,
                                    feeMoney: obj.amount,//this.addValue,
                                    changeType: 0
                                },
                            }).then((res) => {
                                console.log('discountAdminConfirm');
                                console.log(res);
                                if (res.data.success == true) {
                                    this.addValue = 0;
                                    Toast.success('商户充值成功');
                                } else {
                                    Toast.fail(res.data.message);
                                }
                            });
                        } else {
                            Toast.fail(res.data.message);
                        }
                    });
            }
        }
    }
</script>

<style scoped>

</style>