<template>
    <div style="">
        <!-- <van-nav-bar title="成员页面" left-text="返回" left-arrow @click-left="onClickLeft" fixed /> -->
        <van-form validate-first @failed="onFailed" @submit="onSubmit" :disabled='!!disabled'>
            <!-- <van-field v-model="memberNo" name="memberNo" label="用户编号" placeholder="请输入用户编号"
                :rules="[{ required: true, message: '用户编号不能为空' }]" /> -->
            <!-- 通过 pattern 进行正则校验 -->
            <!-- <van-field v-model="memberName" name="memberName" label="用户名" placeholder="请输入用户名"
                :rules="[{ required: true, message: '用户名不能为空' }]" /> -->
            <van-field v-model="phone" name="phone" label="电话号码" placeholder="请输入电话号码"
                :rules="[{ validator1, required: true, message: '请输入正确的电话号码' }]" />
            <van-field v-model="PinCode" name="PinCode" label="Pin码" type="password" placeholder="请输入Pin码"
                :rules="[{ required: true, message: 'Pin码不能为空' }]" />
            <!-- 通过 validator 进行函数校验 -->
            <!-- <van-field v-model="idno" name="idno" label="身份证" placeholder="请输入身份证"
                :rules="[{ validator, required: true, message: '身份证不能为空' }]" /> -->

            <!-- <van-field name="sex" label="性别">
                <template #input>
                    <van-radio-group v-model="sex" direction="horizontal" :disabled='!!disabled'>
                        <van-radio name="0">男</van-radio>
                        <van-radio name="1">女</van-radio>
                    </van-radio-group>
                </template>
            </van-field> -->
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit" v-if="status == 1 || status == 3 ">绑定会员</van-button>
            </div>
        </van-form>
        <div style="margin: 16px;" v-if="status == 1">
            <!-- <van-button round block @click="bind" type="info" native-type="submit" v-if="status==1">绑定会员</van-button> -->
            <van-button v-if="status == 1" round block @click="bind" type="info">注册会员</van-button>
        </div>
        <div style="margin: 16px;" v-if="status == 2">
            <van-button round block @click="unbind" type="info" v-if="status == 2">解绑</van-button>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import { NavBar, Form, Field, RadioGroup, Radio, Toast } from 'vant';
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(NavBar);
Vue.use(Form);
Vue.use(Field); Vue.use(Toast);
import CryptoJS from "crypto-js";
const encry = (dataStr, key) => {
    const keyHex = CryptoJS.enc.Utf8.parse(key);
    const encrypted = CryptoJS.TripleDES.encrypt(dataStr, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
};
export default {
    name: 'AddMember',
    data() {
        return {
            disabled: false,
            memberName: '',
            memberNo: '',
            idno: '230182198012251659',
            sex: '1',
            PinCode: '',
            phone: '',
            pattern: /\d{6}/,
            status: 0
        };
    },

    mounted() {
        // this.getMemeberDetails();
        console.log(this.$route.query.disabled)
        this.disabled = this.$route.query.disabled;
        this.status = this.$route.query.unbind;
        console.log(this.$route.query.unbind)
        if (this.disabled == 'false') {
            console.log(342334)
            this.disabled = !!''
        } else {
            console.log(342334)
            this.disabled = !''
        }
        console.log(this.disabled, !!this.disabled)
        if (this.disabled || this.status == 2) {
            let user = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));
            console.log(user)
            if (user) {
                let { memberName, phone, memberNo, idno, sex } = user;
                this.memberName = memberName;
                this.phone = phone;
                this.memberNo = memberNo;
                this.idno = idno;
                this.sex = sex;
            } else {
                this.getMemeberDetails();
            }
        } else {
            // this.status=0;
        }
    },

    methods: {
        getMemeberDetails() {
            this.$axios
                .request({
                    url: "/api/v1/WeChat/GetMemberDetails",
                    methods: "get",
                    params: {
                        merchantID: localStorage.getItem('MerchantID'),
                        openID: localStorage.getItem('openid')
                    },
                })
                .then((res) => {
                    console.log(res);
                    if (res.data.success) {
                        localStorage.setItem('user', JSON.stringify(res.data))
                        let { memberName, phone, memberNo, idno, sex } = res.data;
                        console.log(memberName, phone, memberNo, idno, sex)
                        this.memberName = memberName;
                        this.phone = phone;
                        this.memberNo = memberNo;
                        this.idno = idno;
                        this.sex = sex;
                    } else {
                        localStorage.setItem('user', '')
                    }
                    //临时
                    // let { memberName, phone, memberNo, idno, sex } = res.data;
                    // this.memberName = memberName;
                    // this.phone = phone;
                    // this.memberNo = memberNo;
                    // this.idno = idno;
                    // this.sex = sex;

                });
        },
        bind() {
            this.$router.push({ path: '/memberReg', query: { disabled: false } });
        },
        unbind() {
            console.log('解绑')
            this.$axios
                .request({
                    url: "/api/v1/WeChat/MemberUnbind",
                    methods: "get",
                    params: {
                        merchantID: localStorage.getItem('MerchantID'),
                        openID: localStorage.getItem('openid'),
                        Phone: this.phone,
                        PinCode: encry(
                            this.PinCode,
                            "270AEF9A6F5A80EA68648CC0"
                        ),
                    },
                })
                .then((res) => {
                    console.log(res);
                    if (res.data.success) {
                        this.$message.success(res.data.message);
                        let url = window.location.origin + window.location.pathname + window.location.search + '/#/addMember?disabled=false&unbind=1';
                        window.location.replace(url)
                        //赋值        
                    } else {
                        this.$message.error(res.data.message);
                    }

                });
        },
        onClickLeft() {
            console.log('返回', this.status);
            if (this.status == 2) {
                this.$router.back()
            } else {
                this.$router.push('/index')
            }

        },
        // 校验函数返回 true 表示校验通过，false 表示不通过
        validator(val) {
            return /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(val);
        },
        // 异步校验函数返回 Promise
        validator1(val) {
            return /^1([358][0-9][4[579]|66|7[0135678]|9[89])[8-9]{8}$/.test(val);
        },
        onFailed(errorInfo) {
            console.log('failed', errorInfo);
        },
        onSubmit(values) {
            console.log('submit', values);
            let obj = {
                phone: this.phone,
                PinCode: encry(
                    this.PinCode,
                    "270AEF9A6F5A80EA68648CC0"
                )
            }
            this.$axios
                .request({
                    method: "GET",
                    url: "/api/v1/WeChat/MemberBinding",
                    params: { ...obj, MerchantID: localStorage.getItem('MerchantID'), openID: localStorage.getItem('openid') },
                })
                .then((res) => {
                    // this.$EventBus.$emit("asd", {
                    //     ...res,
                    //     title: "操作员信息",
                    //     ctx: "删除" + (row.merchantName || ""),
                    // });
                    if (res.data.success) {
                        Toast(res.data.message);
                        let url = window.location.origin + window.location.pathname + window.location.search + '/#/index';
                        window.location.replace(url)
                        // if (window.location.hostname == '192.168.0.102') {
                        //     let url = window.location.protocol + '//' + window.location.host+ window.location.search  + '/#/index';
                        //     console.log(url)
                        //     window.location.replace(url)
                        // } else {
                        //     let url = window.location.protocol + '//' + window.location.host + '/index1.html' + window.location.search+ '/#/index' ;
                        //     console.log(url)
                        //     window.location.replace(url)
                        // }
                    } else {
                        Toast(res.data.message || "操作失败");
                    }
                    // let url = window.location.origin + window.location.pathname + window.location.search + '/#/index';
                    //     window.location.replace(url)
                });
        },
    },
};
</script>
<style  scoped>
* {
    font-size: 14px !important;
}
</style>