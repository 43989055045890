<template>
    <div style="height:100vh;background-color:#58B6FC"
         element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <table border="1" style="width:100%;border-collapse: collapse;">
            <tr>
                <th>名称</th>
                <th>官网</th>
                <th>性质</th>
            </tr>
            <tr>
                <td>C语言中文网</td>
                <td>http://c.biancheng.net/</td>
                <td>教育</td>
            </tr>
            <tr>
                <td>百度</td>
                <td>http://www.baidu.com/</td>
                <td>搜索</td>
            </tr>
            <tr>
                <td>当当</td>
                <td>http://www.dangdang.com/</td>
                <td>图书</td>
            </tr>
        </table>
    </div>
</template>

<script>
    export default {
        name: "parkDiscountAdminRecord",
        data() {
            return {

            }
        },
        mounted() {

        },
        methods: {
            closePage() {

            }
        }
    }
</script>

<style scoped>

</style>