import Vue from 'vue'
import VueRouter from 'vue-router'
//import HomeView from '../views/HomeView.vue'
import YueDetail from '../views/yueDetail.vue'
import YueOder from '../views/yueOder.vue'
import PeopleCenter from '../views/peopleCenter.vue'
import OrderDetail from '../views/orderDetail.vue'
import AddMember from '../views/addMember.vue'
import ModPin from '../views/modPin.vue'
import Dingcan from '../views/dingcan.vue'
import Payorder from '../views/payOrder.vue'
import Login from '../views/login.vue'
import Regref from '../views/regref.vue'
import MemberReg from '../views/memberReg.vue'
import sb from '../views/sb.vue'
import RechargeInfo from '../views/rechargeInfo.vue'
import Userinfo from '../views/userinfo.vue'
import Userface from '../views/userface.vue'
import parkDiscountAdminLogin from '../views/parkDiscountAdminLogin.vue'
import parkDiscountCar from '../views/parkDiscountCar.vue'
import parkQRCodeCoupon from '../views/parkQRCodeCoupon.vue'
import parkFinishInfo from '../views/parkFinishInfo.vue'
import parkCarLogin from '../views/parkCarLogin.vue'
import parkCarVIPRecharge from '../views/parkCarVIPRecharge.vue'
import parkDiscountAdminRecord from '../views/parkDiscountAdminRecord.vue'
import parkCarIssue from '../views/parkCarIssue.vue'
import U404 from '../views/404.vue'
// import vehicleInfoMaintain from '../views/vehicleInfoMaintain'
Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: '登陆',
    component: Login
},
    {
        path: '/parkDiscountAdminLogin',
        name: '商户登录',
        component: parkDiscountAdminLogin
    },
    {
        path: '/parkCarLogin',
        name: '会员登录',
        component: parkCarLogin
    },
    {
        path: '/parkCarVIPRecharge',
        name: '会员中心',
        component: parkCarVIPRecharge
    },
    /*{
        path: '/orderDetail',
        name: 'orderDetail',
        component: OrderDetail
    },
    {
        path: '/peopleCenter',
        name: '个人中心',
        component: PeopleCenter
    },
    {
        path: '/yueDetail',
        name: 'yueDetail',
        component: YueDetail
    },
    {
        path: '/yueOder',
        name: 'yueOder',
        component: YueOder
    },
    {
        path: '/addMember',
        name: 'addMember',
        component: AddMember
    },
    {
        path: '/modPin',
        name: '修改pin码',
        component: ModPin
    },
    {
        path: '/dingcan',
        name: '订餐',
        component: Dingcan
    },
    {
        path: '/payOrder',
        name: '下单',
        component: Payorder
    },
    {
        path: '/regref',
        name: '充值',
        component: Regref
    },
    {
        path: '/memberReg',
        name: '注册',
        component: MemberReg
    },
    {
        path: '/sb',
        name: '十点多',
        component: sb
    },
    {
        path: '/rechargeInfo',
        name: '充值记录',
        component: RechargeInfo
    }, //userinfo
    {
        path: '/userinfo',
        name: '用户信息',
        component: Userinfo
    },
    {
        path: '/U404',
        name: '404',
        component: U404
    },
    {
        path: '/userface',
        name: 'userface',
        component: Userface
    },*/
    {
        path: '/parkDiscountCar',
        name: '商户中心',
        component: parkDiscountCar
    },
    {
        path: '/parkQRCodeCoupon',
        name: '优惠发放',
        component: parkQRCodeCoupon
    },
    {
        path: '/parkFinishInfo',
        name: '操作完成',
        component: parkFinishInfo
    },
    {
        path: '/parkDiscountAdminRecord',
        name: '商家报表',
        component: parkDiscountAdminRecord
    },
    {
        path: '/parkCarIssue',
        name: '车主注册',
        component: parkCarIssue
    },
]
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router