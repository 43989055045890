<template>
    <div style="background-color:#58B6FC;height:100vh;"
         element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <van-form validate-first @submit="onSubmit">
            <van-row class="ds" style="margin:0px 8px 8px 8px;text-align: center;border-radius:5px;">
                <van-col style="margin-top:8px;border-radius:5px;width:100%">
                    <van-field v-model="MerchantNo" name="MerchantNo" label="商户号"
                               :rules="[{ required: true, message: '商户号不能为空' }]"/>
                </van-col>
            </van-row>
            <van-row class="ds" style="margin:0px 8px 8px 8px;text-align: center;border-radius:5px;">
                <van-col style="border-radius:5px;width:100%">
                    <van-field v-model="adminID" name="adminID" label="商家账号"
                               :rules="[{ required: true, message: '商家账号不能为空' }]"/>
                </van-col>
            </van-row>
            <van-row class="ds" style="margin:0px 8px 8px 8px;text-align: center;border-radius:5px;">
                <van-col style="border-radius:5px;width:100%">
                    <van-field v-model="password" name="password" label="登录密码" type="password"/>
                </van-col>
            </van-row>
            <van-row class="ds" style="margin:0px 8px 8px 8px;text-align:center;border-radius:5px;display:flex">
                <van-switch v-model="isSave"/>
                <span style="color:white;margin-left:8px;font-size:24px">保存账号密码</span>
            </van-row>
            <van-row class="ds" style="margin:0px 8px 8px 8px;text-align: center;border-radius:5px;">
                <van-button round block type="info" native-type="submit">提交</van-button>
            </van-row>
        </van-form>
        <van-toast id="van-toast" />
    </div>
</template>

<script>
    import Vue from 'vue';
    import {Form, Col, Row, Field, Toast, Switch} from 'vant';
    Vue.use(Field);
    Vue.use(Col);
    Vue.use(Row);
    Vue.use(Form);
    Vue.use(Toast);
    Vue.use(Switch);
    export default {
        name: "parkDiscountAdminLogin",
        data() {
            return {
                adminID: "",
                password: "",
                MerchantNo: "",
                isSave: false
            }
        },
        mounted() {
            this.MerchantNo = this.getCookie('merchantNo');
            this.adminID = this.getCookie('adminID');
            this.password = this.getCookie('password');
            this.isSave = this.adminID != '';

        },
        methods: {
            // 存储cookie
            setCookie(name, value, day) {
                let date = new Date();
                date.setDate(date.getDate() + day);
                document.cookie = name + '=' + value + ';expires=' + date;
            },
            // 获取cookie
            getCookie(name) {
                let reg = RegExp(name + '=([^;]+)');
                let arr = document.cookie.match(reg);
                if (arr) {
                    return arr[1];
                } else {
                    return '';
                }
            },
            // 删除cookie
            delCookie(name) {
                this.setCookie(name, null, -1);
            },
            onSubmit(values) {
                //console.log(values);
                //localStorage.setItem('discountAdmin', '{"id": "36F58BE5-BBA3-4907-BA66-34CE3D4BEAC3","adminID": "0003","adminName": "piza","passWord": "123456","endDateTime": "0001-01-01 00:00:00","freeMoney": 30,"freeTime": 40,"adminPermission": null,"merchantID": "da42419dc61cb9e203919b932b2c45fe","createBy": "da42419dc61cb9e203919b932b2c45fe"}]');
                //let discountAdmin = {id: "36F58BE5-BBA3-4907-BA66-34CE3D4BEAC3",adminID: "0003",adminName: "piza",passWord: "123456",endDateTime: "0001-01-01 00:00:00",freeMoney: 30,freeTime: 40,adminPermission: null,merchantID: "da42419dc61cb9e203919b932b2c45fe",createBy: "da42419dc61cb9e203919b932b2c45fe"};
                //let url= window.location.origin+window.location.pathname+window.location.search+'#/parkDiscountCar?merchantID=' + discountAdmin.merchantID + '&id=' + discountAdmin.id + '&adminID=' + discountAdmin.adminID + '&adminName=' + discountAdmin.adminName + '';
                //window.location.replace(url)
                this.$axios
                    .request({
                        url: "/api/v1/OpenApi/DiscountAdminLogin",
                        methods: "get",
                        params: {
                            MerchantNo: this.MerchantNo,
                            LoginPwd: this.password,
                            adminID: this.adminID
                        },
                    })
                    .then((res) => {
                        console.log('queryDiscountAdmin');
                        console.log(res);
                        if (res.data.success == true) {
                            if(this.isSave == true) {
                                this.setCookie('merchantNo', this.MerchantNo, 999);
                                this.setCookie('adminID', this.adminID, 999);
                                this.setCookie('password', this.password, 999);
                            }
                            else{
                                this.delCookie('merchantNo');
                                this.delCookie('adminID');
                                this.delCookie('password');
                            }
                            let discountAdmin = res.data;//{id: "36F58BE5-BBA3-4907-BA66-34CE3D4BEAC3",adminID: "0003",adminName: "piza",passWord: "123456",endDateTime: "0001-01-01 00:00:00",freeMoney: 30,freeTime: 40,adminPermission: null,merchantID: "da42419dc61cb9e203919b932b2c45fe",createBy: "da42419dc61cb9e203919b932b2c45fe"};
                            let url= window.location.origin+window.location.pathname+window.location.search+'#/parkDiscountCar?merchantID=' + discountAdmin.merchantID + '&id=' + discountAdmin.id + '&adminID=' + this.adminID + '&adminName=' + discountAdmin.adminName + '&merchantNo=' + this.MerchantNo;
                            window.location.replace(url)
                        } else {
                            Toast.fail(res.data.message);
                            //this.$message.error(res.data.message);
                        }
                    });
            }
        }
    }
</script>

<style scoped>

</style>