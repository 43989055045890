<template>
    <div style="height:100vh;background-color:#58B6FC"
         element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <van-form validate-first @submit="onSubmit">
            <van-row class="ds" style="margin:0px 8px 8px 8px;text-align:center">
                <van-col style="margin-top:8px;display:flex;width:100%;height:50px">
                    <van-tag style="border-radius:5px;width:18%" type="primary" size="large">车牌号码</van-tag>
                    <div style="margin-left:5px;font-size:xx-large;text-align:center;width:10%;border:2px solid gray;background-color:white"
                         @click="showPlateKeyboard = true">{{plateNO[0]}}
                    </div>
                    <div style="margin-left:2px;font-size:xx-large;text-align: center;width:10%;border: 2px solid gray;background-color:white"
                         @click="showPlateKeyboard = true">{{plateNO[1]}}
                    </div>
                    <div style="margin-left:2px;font-size:xx-large;text-align: center;width:10%;border: 2px solid gray;background-color:white"
                         @click="showPlateKeyboard = true">{{plateNO[2]}}
                    </div>
                    <div style="margin-left:2px;font-size:xx-large;text-align: center;width:10%;border: 2px solid gray;background-color:white"
                         @click="showPlateKeyboard = true">{{plateNO[3]}}
                    </div>
                    <div style="margin-left:2px;font-size:xx-large;text-align: center;width:10%;border: 2px solid gray;background-color:white"
                         @click="showPlateKeyboard = true">{{plateNO[4]}}
                    </div>
                    <div style="margin-left:2px;font-size:xx-large;text-align: center;width:10%;border: 2px solid gray;background-color:white"
                         @click="showPlateKeyboard = true">{{plateNO[5]}}
                    </div>
                    <div style="margin-left:2px;font-size:xx-large;text-align: center;width:10%;border: 2px solid gray;background-color:white"
                         @click="showPlateKeyboard = true">{{plateNO[6]}}
                    </div>
                    <div style="margin-left:2px;font-size:xx-large;text-align: center;width:10%;border: 2px solid gray;background-color:white"
                         @click="showPlateKeyboard = true">{{plateNO[7]}}
                    </div>
                </van-col>
            </van-row>
            <van-row class="ds" style="margin:0px 8px 8px 8px;text-align:center;">
                <van-button round block type="info" native-type="submit">提交</van-button>
            </van-row>
        </van-form>
        <van-popup style="background-color:white" v-model:show="showPlateKeyboard" position="bottom">
            <div v-if="plateNO.length == 0" style="margin:6px;text-align: center">
                <van-row v-for="(items, dpindex) in dipartmentKey" class="ds">
                    <van-col v-for="(item, index) in items" style="width:10%;">
                        <div round block style="margin-left:2px;font-size:xx-large;text-align: center;border-radius:5px;border: 1px solid gray;background-color: #1989fa"
                             @click="clickWordKey(item)">{{item}}
                        </div>
                    </van-col>
                </van-row>
            </div>
            <div v-else style="margin:6px;text-align: center">
                <van-row v-for="(items, windex) in wordKey" class="ds">
                    <van-col v-for="(item, index) in items" style="width:10%;">
                        <div v-if="item == '<'" round block
                             style="margin:2px;font-size:xx-large;text-align: center;border-radius:5px;border: 1px solid gray;background-color: #1989fa"
                             @click="clickWordKey(item)">
                            <i class="el-icon-back"></i>
                        </div>
                        <div v-else round block
                             style="margin:2px;font-size:xx-large;text-align: center;border-radius:5px;border: 1px solid gray;background-color: #1989fa"
                             @click="clickWordKey(item)">{{item}}
                        </div>
                    </van-col>
                </van-row>
            </div>
        </van-popup>
    </div>
</template>

<script>
    import Vue from 'vue';
    import {Form, Col, Row, Field, Toast} from 'vant';
    Vue.use(Field);
    Vue.use(Col);
    Vue.use(Row);
    Vue.use(Form);
    export default {
        name: "parkQRCodeCoupon",
        data() {
            return {
                couponID: '',
                couponType: 0,
                qcodeType: 1,
                qCodeValueDate: '',
                merchantID: '',
                discountId: '',
                adminID: '',
                adminName: '',
                showPlateKeyboard: false,
                discountType: 0,
                plateNO: "",
                discountValue: 0,
                dipartmentKey: [['粤', '京', '津', '沪', '渝', '宁', '藏', '桂', '新', '蒙'],
                    ['黑', '滇', '云', '吉', '皖', '鲁', '晋', '桂', '闽', '青'],
                    ['苏', '赣', '冀', '豫', '浙', '琼', '鄂', '湘', '甘', '陇'],
                    ['川', '黔', '贵', '辽', '陕', '秦', '军', '使']],
                wordKey: [['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
                    ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K'],
                    ['L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V'],
                    ['W', 'X', 'Y', 'Z', '学', '警', '港', '澳', '挂', '<']]
            }
        },
        mounted() {
            this.discountID = this.$route.query.qcodeValue;
            if(Number(this.discountID) != 604252403000) {
                this.qcodeType = 2;
                let qCodeValueDate = new Date(Number(this.discountID));
                let dateTimeNow = new Date();
                if (dateTimeNow.getFullYear() != qCodeValueDate.getFullYear() || dateTimeNow.getMonth() != qCodeValueDate.getMonth() || dateTimeNow.getDate() != qCodeValueDate.getDate()
                    || dateTimeNow.getHours() != qCodeValueDate.getHours() || dateTimeNow.getMinutes() - qCodeValueDate.getMinutes() >= 5) {
                    let url = window.location.origin + window.location.pathname + window.location.search + '#/parkFinishInfo?info=优惠卷已过期';
                    window.location.replace(url)
                }
            }
            else{
                this.discountID = new Date().getTime()
            }

            this.couponType = this.$route.query.couponType;
            this.couponID = this.$route.query.couponID;
            this.discountId = this.$route.query.id;
            this.adminID = this.$route.query.adminID;
            this.adminName = this.$route.query.adminName;
            this.merchantID = this.$route.query.MerchantID;
            this.discountValue = this.$route.query.DiscountValue;
            this.discountType = this.$route.query.DiscountType;
        },
        methods: {
            clickWordKey(item) {
                if (item == '<')
                    this.plateNO = this.plateNO.substr(0, this.plateNO.length - 1);
                else if (item == '关闭')
                    this.showPlateKeyboard = false;
                else if (this.plateNO.length <= 8)
                    this.plateNO += item;
            },
            onSubmit(values) {
                console.log(values);
                this.$axios.request({
                    url: "/api/v1/OpenApi/ParkDiscountPlate",
                    method: "POST",
                    data: {
                        MerchantID: this.merchantID,
                        createBy: this.discountId,
                        id: this.discountId,
                        storeCode: this.adminID,
                        storeName: this.adminName,
                        plateNO: this.plateNO,
                        type: this.discountType,
                        value: this.discountValue,
                        discountID: this.discountID,
                        fixQRCode: this.qcodeType,
                        discountPrice: 0,
                        discountTime: 0,
                        discountPercent: 0
                    },
                }).then((res) => {
                    console.log('onSubmit');
                    console.log(res);
                    if (res.data.success == true) {
                        this.merchantID = '';
                        this.discountId = '';
                        this.adminID = '';
                        this.adminName = '';
                        this.plateNO = '';
                        this.discountType = 0;
                        this.discountValue = 0;
                        let url= window.location.origin+window.location.pathname+window.location.search+'#/parkFinishInfo?info=优惠发放成功';
                        window.location.replace(url)
                    } else {
                        Toast.fail(res.data.message);
                        //this.$message.error(res.data.message);
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>