<template>
    <div>

    </div>
</template>
<script>
    import axios from 'axios';
    import Vue from 'vue';
    import {Toast} from 'vant';

    Vue.use(Toast);
    import CryptoJS from "crypto-js";

    let key = '270AEF9A6F5A80EA68648CC0';
    const decrypt = (ciphertext, key) => {
        const keyHex = CryptoJS.enc.Utf8.parse(key);
        const decrpted = CryptoJS.TripleDES.decrypt({
            ciphertext: CryptoJS.enc.Base64.parse(ciphertext)
        }, keyHex, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        })
        return decrpted.toString(CryptoJS.enc.Utf8)
    }
    const encry = (dataStr, key) => {
        const keyHex = CryptoJS.enc.Utf8.parse(key);
        const encrypted = CryptoJS.TripleDES.encrypt(dataStr, keyHex, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString();
    };
    export default {
        name: 'Login',

        data() {
            return {
                APPID: '',
                SECRET: '',
                // APPID: 'wxddf1961a13ac0db4', //正式版本
                // SECRET: '807c18ec0a1a3e6252d1968abeed8c11',
                code: '',
                src: '',
                href: '',
                type: 0
            };
        },

        mounted() {
            localStorage.clear();

            this.type = this.getUrlParam('type');
            localStorage.setItem("MerchantID", this.getUrlParam('merchantID'));
            let mer = this.getUrlParam('MerchantID') || this.getUrlParam('merchantID')
            console.log(mer)

            // this.getOpenId();
            Toast.loading({
                message: '登陆中...',
                forbidClick: true,
                loadingType: 'spinner',
            });
            console.log(window.location)
            console.log(window.location.protocol + '//' + window.location.host + '/#/index')
            // localStorage.setItem('openid', 'oyLwT5761otUwfe2XY28wi_sjX_2')
            localStorage.setItem('MerchantID', mer.replace(/#\//, ''))
            //获取相关的参数
            if (localStorage.getItem('payParameters')) {
                let payParameters = localStorage.getItem('payParameters')
                let payParametersJSON = payParameters && JSON.parse(payParameters)
                this.APPID = payParametersJSON.weChatAppID;
                this.SECRET = payParametersJSON.weChatAppSecret;
            }

            this.GetPaymentParametersc().then(res => {
                console.log(res)
                this.getCode()
            }).catch(err => {
                console.log('2121212')
                console.log(err)
                let url = window.location.origin + window.location.pathname + window.location.search + '/#/U404';
                history.pushState(null, null, document.URL); //清空之前的历史记录
                window.location.replace(url)
            })
            // if (localStorage.getItem('payParameters')) {
            //
            // } else {
            //     this.GetPaymentParameters()
            // }


        },

        methods: {
            GetPaymentParametersc() {
                return new Promise((resolve, reject) => {
                    this.$axios
                        .request({
                            method: "GET",
                            url: "/api/v1/OpenApi/GetPaymentParameters",
                            params: {
                                merchantID: localStorage.getItem("MerchantID"),
                            },
                        })
                        .then((res) => {
                            console.log(res.data);
                            let obj = {
                                alipay_AliappId: res.data.alipay_AliappId && decrypt(res.data.alipay_AliappId, key),
                                alipay_Pid: res.data.alipay_Pid && decrypt(res.data.alipay_Pid, key),
                                alipay_public_key: res.data.alipay_public_key && decrypt(res.data.alipay_public_key, key),
                                merchant_private_key: res.data.merchant_private_key && decrypt(res.data.merchant_private_key, key),
                                merchant_public_key: res.data.merchant_public_key && decrypt(res.data.merchant_public_key, key),
                                weChatAppID: res.data.weChatAppID && decrypt(res.data.weChatAppID, key), //|| 'wxddf1961a13ac0db4'
                                weChatAppSecret: res.data.weChatAppSecret && decrypt(res.data.weChatAppSecret, key), //|| '807c18ec0a1a3e6252d1968abeed8c11'
                                weChatKey: res.data.weChatKey && decrypt(res.data.weChatKey, key),
                                weChatMchID: res.data.weChatMchID && decrypt(res.data.weChatMchID, key),
                                weChatNotify_Url: res.data.weChatNotify_Url && decrypt(res.data.weChatNotify_Url, key),
                                weChatSslcert_Path: res.data.weChatSslcert_Path && decrypt(res.data.weChatSslcert_Path, key),
                            }
                            if (!obj.weChatAppID || !obj.weChatAppSecret) {
                                console.log('丢雷')
                                reject(404)

                                // let url = window.location.origin + window.location.pathname + window.location.search + '/#/U404';
                                // history.pushState(null, null, document.URL); //清空之前的历史记录
                                // window.location.replace(url)
                                // return false
                            } else {
                                //                 APPID: 'wxddf1961a13ac0db4', //正式版本
                                // // SECRET: 'affc4e60e9951ea829984b9af40ae68f',
                                // SECRET: '807c18ec0a1a3e6252d1968abeed8c11',
                                this.APPID = obj.weChatAppID;
                                this.SECRET = obj.weChatAppSecret;
                                localStorage.setItem('payParameters', JSON.stringify(obj))
                                resolve(obj)
                            }
                        });
                })

            },
            /*getToken(code) {
                this.$axios
                    .request({
                        url: "/api/v1/OpenApi/GetToken",
                        method: "get",
                        params: {
                            secretkey: "6563a009f5270b78e5ef757578659e18",
                            appid: "ap07f3e919592bacd8",
                        },
                    })
                    .then((res) => {
                        console.log(res);
                        // let time = new Date(res.data.validityDate).getTime() - 1 * 60 * 1000;
                        localStorage.setItem("authkey", res.data.token);
                        localStorage.setItem("validityDate", res.data.validityDate.replace(/-/g, '/'));
                        this.getOpenId(code) //把code传给后台获取用户信息
                    });
            },*/
            getCode() { // 非静默授权，第一次有弹框
                const code = this.getUrlParam('code') // 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取openId
                const local = window.location.href;

                if (code == null || code === '') {
                    let cc = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.APPID}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_userinfo#wechat_redirect`
                    // window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + 'wxec50473d0c0ec298' + '&redirect_uri=' + encodeURIComponent(local) + '&response_type=code&scope=snsapi_base&state=1#wechat_redirect'
                    console.log(window.location.href)
                    window.location.href = cc;
                } else {
                    //this.getToken(code)
                    this.getOpenId(code) //把code传给后台获取用户信息
                    // this.getOpenId(code) //把code传给后台获取用户信息
                }
            },
            //http://192.168.0.102:8080/?code=011we60w3WQ5VY2Qy30w35OAzj3we60v&state=123#/
            getUrlParam(id) {//封装方法
                var regExp = new RegExp('([?]|&)' + id + '=([^&]*)(&|$)');
                var result = window.location.href.match(regExp);
                if (result) {
                    return decodeURIComponent(result[2]);
                } else {
                    return null;
                }

            },
            /*GetPaymentParameters() {
                if (localStorage.getItem('payParameters')) {
                    let payParameters = localStorage.getItem('payParameters')
                    let payParametersJSON = payParameters && JSON.parse(payParameters)
                    this.APPID = payParametersJSON.weChatAppID;;
                    this.SECRET = payParametersJSON.weChatAppSecret;
                    this.getWechatUserId();
                } else {
                    this.$axios
                        .request({
                            method: "GET",
                            url: "/api/v1/OpenApi/GetPaymentParameters",
                            params: {
                                merchantID: localStorage.getItem("MerchantID"),
                            },
                        })
                        .then((res) => {
                            console.log(res.data);
                            let obj = {
                                alipay_AliappId: res.data.alipay_AliappId && decrypt(res.data.alipay_AliappId, key),
                                alipay_Pid: res.data.alipay_Pid && decrypt(res.data.alipay_Pid, key),
                                alipay_public_key: res.data.alipay_public_key && decrypt(res.data.alipay_public_key, key),
                                merchant_private_key: res.data.merchant_private_key && decrypt(res.data.merchant_private_key, key),
                                merchant_public_key: res.data.merchant_public_key && decrypt(res.data.merchant_public_key, key),
                                weChatAppID: res.data.weChatAppID && decrypt(res.data.weChatAppID, key),
                                weChatAppSecret: res.data.weChatAppSecret && decrypt(res.data.weChatAppSecret, key),
                                weChatKey: res.data.weChatKey && decrypt(res.data.weChatKey, key),
                                weChatMchID: res.data.weChatMchID && decrypt(res.data.weChatMchID, key),
                                weChatNotify_Url: res.data.weChatNotify_Url && decrypt(res.data.weChatNotify_Url, key),
                                weChatSslcert_Path: res.data.weChatSslcert_Path && decrypt(res.data.weChatSslcert_Path, key),
                            }
                            // console.log({...res.data,...obj});
                            // this.$EventBus.$emit("asd", {
                            //   ...res,
                            //   title: "支付参数",
                            //   ctx: "获取支付参数信息" + "",
                            // });
                            this.APPID = obj.weChatAppID;
                            this.SECRET = obj.weChatAppSecret;
                            console.log(obj)
                            localStorage.setItem('payParameters', JSON.stringify(obj))
                            // cAPPID = obj.weChatAppID;
                            // cAppSecret = obj.weChatKey; //支付密钥
                            // cmch_id = obj.weChatMchID;
                            // this.form = res.data;
                            this.getWechatUserId();
                            // this.getCode();
                        });
                }
            },
            getWechatUserId() {
                this.$axios
                    .request({
                        method: "GET",
                        url: "/api/v1/WeChat/GetsystemParameter",
                        params: {
                            merchantID: localStorage.getItem("MerchantID"),
                        },
                    })
                    .then((res) => {
                        console.log(res);
                        if (res.data.success) {
                            localStorage.setItem("wechatUserID", res.data.wechatUserID)
                        } else {

                        }
                        this.getMemeberDetails()
                    });
            },*/
            // access_token: "59_CtL9ugoa2zSG8n0AGoPssX4cp_G203CZ8qdih1JVmN2nMZzo91XFyFrr9GbHJCu2JQiUcCmAAgLpbVku7lz0Z5T6sjUZ5E7Q8Xmp0exsX_c"
            // expires_in: 7200
            // openid: "oyLwT5761otUw6q2XY28wi_sjX_8"
            // refresh_token: "59_U4RGBJY4N5tlJ0dQdm5y1Z7sIXp8QZh4MlKzIAWJKLc_wi3fuIu0To-6A007QOwuUt9w9WX6M3NyNFVA6W25QCWdxnd_lvYko0DiYna5RmQ"
            // scope: "snsapi_base"
            getOpenId(CODE) {
                console.log(CODE)
                let target = `https://api.weixin.qq.com/sns/oauth2/access_token?appid=${this.APPID}&secret=${this.SECRET}&code=${CODE}&grant_type=authorization_code`;
                // return false;
                console.log(window.g.tokenBaseUrl + '/getdata?url=')
                axios.get(window.g.tokenBaseUrl + '/getdata?url=' + target).then((res) => {
                    // this.$EventBus.$emit('asd',res)
                    console.log(res.data)
                    let {access_token, expires_in, openid, refresh_token} = res.data;
                    localStorage.setItem('access_token', access_token)
                    localStorage.setItem('expires_in', expires_in * 1000 + Date.now())
                    localStorage.setItem('openid', openid)
                    localStorage.setItem('refresh_token', refresh_token)
                    //发送日志
                    if (window.g.loglevenl == 3) {
                        //日志存贮
                        axios.request({
                            method: "get",
                            url: window.g.logBaseUrl + "/wechatlogrecord",
                            params: {
                                url: window.g.tokenBaseUrl + '/getdata?url=' + target,
                                data: '',
                                level: window.g.loglevenl,
                                type: "info",
                                res: JSON.stringify(res.data).substring(0, 2500),
                                method: 'get'
                            },
                            success: (res) => {
                                // console.log(res);
                            },
                            fail: (err) => {
                                // // console.log(err);
                            },
                        });
                    }
                    this.getWXUserInfo(access_token, openid)
                })
            },
            getWXUserInfo(res, openid) {
                console.log(res)
                let target = `https://api.weixin.qq.com/sns/userinfo?openid=${openid}&access_token=${res}&lang=zh_CN`;
                // return false;
                console.log(window.g.tokenBaseUrl + '/getdata?url=')
                axios.get(window.g.tokenBaseUrl + '/getdata?url=' + target).then((res) => {
                    // this.$EventBus.$emit('asd',res)
                    console.log(res.data)
                    let {nickname, sex, province, city, country, headimgurl} = res.data;
                    localStorage.setItem('img', headimgurl)
                    //发送日志
                    if (window.g.loglevenl == 3) {
                        //日志存贮
                        axios.request({
                            method: "get",
                            url: window.g.logBaseUrl + "/wechatlogrecord",
                            params: {
                                url: window.g.tokenBaseUrl + '/getdata?url=' + target,
                                data: '',
                                level: window.g.loglevenl,
                                type: "info",
                                res: JSON.stringify(res.data).substring(0, 2500),
                                method: 'get'
                            },
                            success: (res) => {
                                // console.log(res);
                            },
                            fail: (err) => {
                                // // console.log(err);
                            },
                        });
                    }

                    if(this.type == 0) {
                        let url = window.location.origin + window.location.pathname + window.location.search + '#/parkDiscountAdminLogin';
                        //let url = window.location.origin + window.location.pathname + window.location.search + '#/parkCarLogin?MerchantID=' + localStorage.getItem("MerchantID");
                        window.location.replace(url)
                    }
                    else {
                        let url = window.location.origin + window.location.pathname + window.location.search + '#/parkCarLogin?MerchantID=' + localStorage.getItem("MerchantID");
                        window.location.replace(url)
                    }
                    //this.GetPaymentParameters();

                })
            },
            /*getMemeberDetails() {
                this.$axios
                    .request({
                        url: "/api/v1/WeChat/GetMemberDetails",
                        method: "get",
                        params: {
                            merchantID: localStorage.getItem('MerchantID'),
                            openID: localStorage.getItem('openid')
                        },
                    })
                    .then((res) => {
                        console.log(res);
                        // return false;
                        console.log(res.data.success == false && res.data.id == null)
                        // return false;
                        if (res.data.success == false && res.data.id == null) {
                            // console.log('去往绑定页面')
                            localStorage.setItem('user', JSON.stringify(res.data))
                            let url = window.location.origin + window.location.pathname + window.location.search + '/#/addMember?disabled=false&unbind=1';
                            //  history.pushState(null, null, document.URL); //清空之前的历史记录
                            window.location.replace(url)
                        } else {
                            localStorage.setItem('user', '')
                            let url = window.location.origin + window.location.pathname + window.location.search + '/#/index';
                            // history.pushState(null, null, document.URL); //清空之前的历史记录
                            window.location.replace(url)
                            // console.log('去往首页')
                            // let url = 'http://43.138.48.29/index1.html' + window.location.search + '/#/index'
                            // window.location.replace(url)
                        }

                    });
            },*/
        },
    };
</script>
<style scoped>

</style>