<template>
    <div style="padding-top:46px;" v-loading.fullscreen.lock="loading" element-loading-text="正在退单..."
        element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <van-nav-bar title="充值记录" fixed @click-right="selOrder">
            <template #right>
                <van-icon name="search" size="18" />
            </template>
        </van-nav-bar>
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh" success-text="刷新成功">
            <div>
                <!-- <van-list v-model="loadingc" :finished="finished" finished-text="没有更多了" @load="onLoadcc"> -->
                <van-card v-for='item in areas' :key='item.id' :centered='!!abc'
                    :tag="item.isPay == 0 ? '未支付' : item.isPay == 1 ? '已支付' : '已退款'"
                    :title="item.memberName + ' - ' + (item.isPay == 0 ? '未支付' : item.isPay == 1 ? '已支付' : '已退款')">
                    <template #tags>
                        <div style='color:red;margin:5px 0;'>支付时间:{{  item.payTime  }}</div>
                        <div style='color:red;margin:5px 0;'>订单号:{{  item.payOrderNo  }}</div>
                        <div style='margin:5px 0;'>支付金额:{{  item.amount  }}</div>
                        <div style='margin:5px 0;'>支付方式:{{  item.payTypeName  }}</div>
                        <div style='margin:5px 0;'>订单类型:{{  item.orderType == 0 ? '线下订单' : ' 线上订单'  }}</div>
                        <div style='margin:5px 0;'>充值时间:{{  item.payTime  }}</div>
                        <div style='margin:5px 0;'>充值类型:{{  item.rechargeTypeName  }}</div>
                        <div style='margin:5px 0;'>增减类型:{{  item.rechargeModel == 0 ? '增款' : '减款'  }}</div>
                    </template>
                    <template #footer v-if='item.isPay == 1 && item.rechargeModel == 0'>
                        <van-button size="mini" style="color:#fff;" @click='orderRefund(item)'>退款</van-button>
                    </template>
                </van-card>

                <van-empty v-if='areas.length == 0' description="无订单信息" />
                <!-- </van-list> -->
            </div>

        </van-pull-refresh>
        <!-- 搜索面板 -->
        <van-popup v-model="show" position="bottom" closeable close-icon="close">
            <div>
                <div style='margin-top:20px;text-align:center;font-size:16px;margin-bottom:10px;'>查询条件</div>

                <van-cell title="订单日期区间" :value="date" @click="show2 = true" />
                <van-calendar v-model="show2" type="range" @confirm="onConfirm" :min-date="minDate"
                    :max-date="maxDate" />
                <van-form @submit="onSubmit">
                    <van-field name="radio" label="支付状态" placeholder="支付状态">
                        <template #input>
                            <van-radio-group v-model="obj.OrderState" direction="horizontal">
                                <van-radio name="0" icon-size="12px">未支付</van-radio>
                                <van-radio name="1" icon-size="12px">已支付</van-radio>
                                <van-radio name="2" icon-size="12px">已退款</van-radio>
                                <van-radio name="99" icon-size="12px">全部</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>
                    <!-- <van-field name="订单日期" label="订单日期" placeholder="订单日期">
                        <template #input>
                            <van-cell :value="obj.OrderDate" @click="show3 = true" />
                            <van-calendar v-model="show3" @confirm="onConfirm1" :min-date="minDate" :max-date="maxDate"/>
                        </template>
                    </van-field> -->
                    <!-- <van-field v-model="obj.OrderNo" type="number" name="订单号" label="订单号" placeholder="订单号" /> -->
                    <div style="margin: 16px;">
                        <van-button round block type="info" native-type="submit">提交</van-button>
                    </div>
                </van-form>
            </div>
        </van-popup>
    </div>
</template>
<script>
let ccreateBy = '';
import Vue from 'vue';
import { Tab, Tabs, Button, NavBar, Card, PullRefresh, Toast, Calendar, Popup, Form, Field, Cell, CellGroup, RadioGroup, Radio, Empty, Dialog, List } from 'vant';
Vue.use(Tab); Vue.use(Form); Vue.use(List);
Vue.use(Field); Vue.use(Cell); Vue.use(Dialog);
Vue.use(CellGroup);
Vue.use(Radio); Vue.use(Empty);
Vue.use(RadioGroup);
Vue.use(Tabs); Vue.use(Popup);
Vue.use(Button); Vue.use(Toast);
Vue.use(NavBar); Vue.use(Calendar);
Vue.use(Card); Vue.use(PullRefresh);
import NP from 'number-precision'
export default {
    name: 'OrderDetail',

    data() {
        return {
            active: 0,
            abc: true,
            count: 0,
            isLoading: false,
            loading: false,
            loadingc: false,
            finished: false,
            minDate: new Date(2022, 6, 1), maxDate: new Date(2025, 12, 31),
            show: false,
            show2: false,
            show3: false,
            page: 99,
            curPage: 1,

            userName: '',
            obj: {  //查询条件
                OrderState: '99',
                OrderDate: '',
                OrderNo: '',
            },
            balance: '',
            memberId: '',
            areas: [],
            areas1: [],
            areas2: [],
            date: '',
            a: '', b: '', a1: '', b1: '',
            actions: '', type: '',
            recordCount: 0,
        };
    },

    mounted() {
        // 默认查询7天之前的日期
        let a = this.dateFormat1(new Date());
        let b = this.dateFormat1(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
        this.a = a;
        this.b = b;
        console.log(a, b, this.a, this.b)
        let user = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));
        if (user && user.id) {
            let { memberName, phone, balance, id } = user;
            this.userName = memberName + ' / ' + phone;
            this.balance = balance;
            this.memberId = id;
            this.getorderInfo();
        } else {
            this.getMemeberDetails(1);
        }
        this.GetDataDictionaryInfo();
        // this.getWechatUserId()
        let wechatuser = localStorage.getItem("wechatUserID")
        console.log(wechatuser)
        ccreateBy = wechatuser;

    },
    filters: {
        imgc(url) {
            // console.log(url)
            let urlc =
                window.g.baseUrl +
                "/api/v1/OpenApi/PullImage?PicName=" +
                url +
                "&PathType=2";
            // console.log(urlc);
            return urlc;
        }
    },

    methods: {

        onLoadcc() {
            console.log('你TM 怎么不出发了')
            console.log(Math.ceil(17 / 5), this.curPage)
            if (Math.ceil(17 / 5) < this.curPage) {
                this.loadingc = false;
                this.finished = true;
                return false;
            }

            this.curPage += 1;
            let bb = this.b1 ? this.b1 : this.b;
            let aa = this.a1 ? this.a1 : this.a;
            this.getorderInfo(bb, aa, this.obj.OrderState);
        },
        orderRefund(item) {
            console.log('退单', item)
            Dialog.confirm({
                title: '提示',
                message: '此操作将退票校验订单, 是否继续?',
            })
                .then((res) => {
                    console.log(res)
                    // this.loading=true;
                    this.WriteRechargeInfo(item)
                })
                .catch((err) => {
                    console.log(err)
                    // on cancel
                });
        },
        // 写充值信息
        WriteRechargeInfo(item) {
            this.loading = true;
            console.log(item, this.actions, NP.minus(item.balance, item.amount))
            let aa = {
                id: this.guid(),
                merchantID: localStorage.getItem("MerchantID"),
                memberID: item.memberID,
                memberCardNo: "",
                amount: item.amount,
                receiptsAmount: item.amount,
                balance: NP.minus(item.balance, item.amount),
                giftBalance: 0,
                subsidies: 0,
                surplusTimes: 0,
                rechargeTime: this.dateFormat(),
                rechargeType: this.actions[1].id, //充值类型
                rechargeModel: 1,
                rechargeMode: this.actions[0].id,
                isGreyRecord: 1,
                createBy: ccreateBy,
                // isGreyRecord: this.form.rechargeType == s[0].id ? 0 : 1,
                remarks: "",
            };
            console.log(aa)
            this.$axios
                .request({
                    method: "POST",
                    url: "/api/v1/OpenApi/WriteRechargeInfo",
                    data: { ...aa },
                })
                .then((res) => {
                    console.log(res);
                    if (res.data.success) {
                        console.log(res.data);
                        if ("微信" !== item.payTypeName) {
                            this.UpdateRechargePayState(aa, item, '现金');
                        } else {
                            this.CodeScanningRefund(aa, item);
                            console.log('zhanhu')
                        }
                        console.log(aa.amount)
                    } else {
                        this.$message({
                            type: "error",
                            message: res.data.message || "操作失败",
                        });
                    }
                });
        },
        CodeScanningRefund(a, item) {
            console.log(a, item)
            // return false;
            this.$axios
                .request({
                    method: "POST",
                    url: '/api/v1/OpenApi/CodeScanningRefund',
                    data: {
                        id: this.guid(),
                        merchantID: localStorage.getItem("MerchantID"),
                        //会员ID
                        MemberID: a.memberID,
                        //支付订单号--可为空
                        PayOrderNo: item.payOrderNo || '',
                        //退款金额
                        RefundAmount: Number(a.amount),
                        //总金额--可为空
                        TotalAmount: Number(a.amount),
                        //支付渠道---0 官方 1 预留
                        PaymentChannels: 0,
                        //支付方式---0 微信 1 支付宝
                        PaymentType: this.actions[0].id
                    },
                })
                .then((res) => {
                    console.log(res);
                    if (res.data.message == '退款成功') {
                        this.$message({
                            type: "success",
                            message: res.data.message,
                        });
                        this.UpdateRechargePayState(a, res.data)
                    } else {
                        this.$message({
                            type: "warning",
                            message: res.data.message || "操作失败",
                        });
                        this.UpdateRechargePayState(a, res.data)
                    }


                });
        },
        // 更新充值信息
        UpdateRechargePayState(a, refuse = '', name) {
            console.log(a, refuse)
            let isPayc = 0
            if (name == '现金' && refuse == '') {
                isPayc = 1
            } else {
                isPayc = refuse.refundState || 0  //退款才有的状态
            }
            this.$axios
                .request({
                    method: "POST",
                    url: "/api/v1/OpenApi/UpdateRechargePayState",
                    data: {
                        id: a.id,
                        merchantID: a.merchantID,
                        MemberType: 0,
                        payType: a.rechargeMode,
                        payState: isPayc,   //支付状态 0 未支付 1 已支付 2 已退款
                        payTime: refuse.refundDateTime || a.rechargeTime,
                        payOrderNo: refuse.refundTradeNo || a.id,
                        isPay: isPayc, //支付状态 0 未支付 1 已支付 2 已退款
                    },
                })
                .then((res) => {
                    console.log(res)

                    if (res.data.success) {
                        console.log(res.data);
                        // this.$message({
                        //     type: "success",
                        //     message:res.data.message,
                        // });
                    } else {
                        this.$message({
                            type: "error",
                            message: res.data.message || "操作失败",
                        });
                    }
                    this.getorderInfo()
                    this.loading = false;
                });
        },
        onSubmit() {
            console.log('成功过', this.obj, this.a, this.b)
            let bb = this.b1 ? this.b1 : this.b;
            let aa = this.a1 ? this.a1 : this.a;
            this.getorderInfo(bb, aa, this.obj.OrderState);
            this.show = false;
        },
        formatDate(date) {
            return ''+`${date.getFullYear()}/${date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)}/${date.getDate() > 9 ? date.getDate() : '0' + date.getDate()}`;
        },
        onConfirm(date) {
            console.log(date[0], date[1])
            const [start, end] = date;
            this.show2 = false;
            this.date = `${this.formatDate(start)} - ${this.formatDate(end)}`;
            this.b1 = `${this.formatDate(start)}`.replace(/\//g, '');
            this.a1 = `${this.formatDate(end)}`.replace(/\//g, '');
        },
        onConfirm1(date) {
            console.log(date)
            this.show3 = false;
            this.obj.OrderDate = `${this.formatDate(date)}`;
        },
        GetDataDictionaryInfo() {
            let datac = localStorage.getItem("DictionaryInfo")
            console.log(JSON.parse(datac))
            if (datac) {
                let models = JSON.parse(datac);
                var type = ''; let payArr = []
                models.forEach((item) => {
                    // 身份证
                    // console.log(item)
                    if (item.dictionaryContent == '微信') {
                        type = item;
                        payArr.push({ ...item, name: item.dictionaryContent })
                    }
                    if (item.dictionaryContent == '现金提取') {
                        type = item;
                        payArr.push({ ...item, name: item.dictionaryContent, subname: '' })
                    }
                });
                this.type = type.id;
                this.actions = payArr
            } else {
                this.type = "";
                // this.$message.error(res.data.message);
            }
            return false;
            this.$axios
                .request({
                    url: "/api/v1/OpenApi/GetDataDictionaryInfo",
                    method: "get",
                    params: {
                        node: 999,
                        MerchantID: localStorage.getItem("MerchantID"),
                    },
                })
                .then((res) => {
                    // this.$EventBus.$emit('asd',res)
                    console.log(res);
                    if (res.data.success) {
                        var type = ''; let payArr = []
                        res.data.models.forEach((item) => {
                            // 身份证
                            // console.log(item)
                            if (item.dictionaryContent == '微信') {
                                type = item;
                                payArr.push({ ...item, name: item.dictionaryContent })
                            }
                            if (item.dictionaryContent == '现金提取') {
                                type = item;
                                payArr.push({ ...item, name: item.dictionaryContent, subname: '' })
                            }
                        });
                        this.type = type.id;
                        this.actions = payArr
                    } else {
                        this.type = "";
                        // this.$message.error(res.data.message);
                    }
                    console.log(this.type)
                });
        },
        getMemeberDetails(isc) {
            console.log(isc)

            this.$axios
                .request({
                    url: "/api/v1/WeChat/GetMemberDetails",
                    methods: "get",
                    params: {
                        merchantID: localStorage.getItem('MerchantID'),
                        openID: localStorage.getItem('openid')
                    },
                })
                .then((res) => {
                    console.log(res);
                    if (res.data.success) {
                        localStorage.setItem('user', JSON.stringify(res.data))
                        let { memberName, phone, balance, id } = res.data;
                        this.userName = memberName + ' / ' + phone;
                        this.balance = balance
                        this.memberId = id;
                        if (isc == 1) {
                            console.log(isc)
                            this.getorderInfo(OrderNo, this.b, this.a, 99);
                        }

                    } else {
                        localStorage.setItem('user', '')
                        this.$message({
                            message: res.data.message,
                            type: 'warning'
                        });
                    }

                    //临时
                    // let { memberName, phone, memberNo, idno, sex } = res.data;
                    // this.memberName = memberName;
                    // this.phone = phone;
                    // this.memberNo = memberNo;
                    // this.idno = idno;
                    // this.sex = sex;

                });
        },
        selOrder() {
            console.log('搜索订单')
            this.show = true;
        },
        onRefresh() {
            let bb = this.b1 ? this.b1 : this.b;
            let aa = this.a1 ? this.a1 : this.a;
            this.curPage = 1;
            this.getorderInfo(bb, aa, this.obj.OrderState);
        },
        getorderInfo(StartDate = this.b, EndDate = this.a, isPay = 99) {
            console.log(isPay)
            this.loading = true;
            this.loadingc = true;
            this.finished = false;
            this.$axios
                .request({
                    url: "/api/v1/WeChat/GetRechargeInfo?Orderby",
                    methods: "get",
                    params: {
                        merchantID: localStorage.getItem('MerchantID'),
                        StartIndex: (this.curPage - 1) * this.page,
                        MemberID: this.memberId,
                        EndIndex: this.curPage * this.page,
                        StartDate: StartDate,
                        EndDate: EndDate,
                        MemberType: 0,
                        isPay: isPay
                    },  //MerchantID,MemberID,OrderNo,MemberType,StartDate,&EndDate,&Orderby,&StartIndex,&EndIndex,
                })
                .then((res) => {
                    console.log(res);
                    if (res.data.success) {
                        //赋值     
                        console.log(res.data)
                        // this.areas = res.data.models;
                        // let a = this.areas.concat(res.data.models);
                        // let b = this.quchong(a)
                        // console.log(b)
                        if (res.data.models.length > 0) {
                            this.areas = res.data.models;
                            this.recordCount = res.data.recordCount
                        }
                    } else {
                        // this.areas = [];
                        this.areas = [];
                        this.recordCount = 0
                        console.log(this.areas)
                    }
                    this.loading = false;
                    this.isLoading = false;

                    setTimeout(() => {
                        this.loadingc = false;
                        this.finished = false;
                    }, 1000)
                }).catch((err) => {
                    Toast(err)
                })
        },
        quchong(arr) {
            var result = [];
            var obj = {};
            for (var i = 0; i < arr.length; i++) {
                if (!obj[arr[i].id]) {
                    result.push(arr[i]);
                    obj[arr[i].id] = true;
                }
            }
            return result
        },
        titlechange(name, title) {
            console.log(name, title)
            let OrderState = name == 0 ? 99 : name == 1 ? 0 : 1;
            let bb = this.b1 ? this.b1 : this.b;
            let aa = this.a1 ? this.a1 : this.a;
            this.getorderInfo(this.obj.OrderNo, bb, aa, OrderState);
        },
        onClickLeft() {
            console.log('返回');
            this.$router.back();
        },
        toding(index) {
            console.log(index)
            this.a1 = '';
            this.b1 = '';
            this.obj.OrderState = '';
            this.obj.OrderNo = '';
            this.$router.push('/dingcan')
        },
        dateFormat() {
            var datec = new Date();
            var year = datec.getFullYear();
            /* 在日期格式中，月份是从0开始的，因此要加0
             * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
             * */
            var month =
                datec.getMonth() + 1 < 10
                    ? "0" + (datec.getMonth() + 1)
                    : datec.getMonth() + 1;
            var day = datec.getDate() < 10 ? "0" + datec.getDate() : datec.getDate();
            var hours =
                datec.getHours() < 10 ? "0" + datec.getHours() : datec.getHours();
            var minutes =
                datec.getMinutes() < 10 ? "0" + datec.getMinutes() : datec.getMinutes();
            var seconds =
                datec.getSeconds() < 10 ? "0" + datec.getSeconds() : datec.getSeconds();
            // 拼接
            return ''+year + month + day + hours + minutes + seconds;
        },
        dateFormat1(dateq) {
            var datec = new Date(dateq);
            var year = datec.getFullYear();
            /* 在日期格式中，月份是从0开始的，因此要加0
             * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
             * */
            var month =
                datec.getMonth() + 1 < 10
                    ? "0" + (datec.getMonth() + 1)
                    : datec.getMonth() + 1;
            var day = datec.getDate() < 10 ? "0" + datec.getDate() : datec.getDate();
            // var hours =
            //   date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
            // var minutes =
            //   date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
            // var seconds =
            //   date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
            // 拼接
            return ''+ year + month + day
        },
        guid() {
            function S4() {
                return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
            }
            return S4() + S4() + S4() + S4() + S4() + S4() + S4();
        }
    },
};
</script>
<style  scoped>
.empty {
    display: inline-block;
    text-align: center;
    margin-top: 20%;
    width: 100%;
    color: #999;
}

button {
    padding: 0 10px;
    height: 26px;
    line-height: 26px;
    background-color: #f26a40;
    border-color: #f26a40;
}

.van-card__thumb {
    top: 14px;
}
</style>